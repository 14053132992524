import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Box,
  Button,
  Dialog,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';

import { DashClients } from '../../axios';
import Loader from '../../Components/Loader';
import { getComparator, Order } from '../../Helpers/TableHelper';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import theme from '../../theme';
import { ClientTypeEnum, ModeEnum, ReactLocationState } from '../../Types';
import { Client, ClientSearchFormValue, DashClientResult } from '../../Types/ClientSponsor';
import DeleteClientDialog from './Dialogs/DeleteClientDialog';

const useStyles = makeStyles(() => ({
  buttonText: {
    fontWeight: 700,
    padding: 0,
    minWidth: 0,
    margin: 0,
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  textCell: {
    fontWeight: 300,
    fontSize: '11pt',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.primary.dark,
  },
}));

interface DashClientResultsProps {
  fetchedResults: Array<Client>;
  clientSearchFormValue: ClientSearchFormValue;
  setResultsCsv: Dispatch<SetStateAction<DashClientResult[] | null>>;
  handleSearch: () => Promise<void>;
}

interface HeadCell {
  id: keyof DashClientResult;
  label: string;
  width: number;
}

const DashClientResults: FC<DashClientResultsProps> = ({
  fetchedResults,
  clientSearchFormValue,
  handleSearch,
  setResultsCsv,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const { state } = useLocation<ReactLocationState>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof DashClientResult>('name');

  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const headCells: readonly HeadCell[] = [
    {
      id: 'code',
      label: t('dashclients.tableColClientId'),
      width: 10,
    },
    {
      id: 'name',
      label: t('dashclients.tableColName'),
      width: 45,
    },
    {
      id: 'type',
      label: t('dashclients.tableColType'),
      width: 20,
    },
    {
      id: 'status',
      label: t('dashclients.tableColStatus'),
      width: 10,
    },
  ];

  useEffect(() => {
    setResultsCsv(
      fetchedResults
        .map((oneFetchedResult) => ({
          id: oneFetchedResult.id,
          code: oneFetchedResult.code ? oneFetchedResult.code : '',
          name: i18n.language.startsWith('en') ? oneFetchedResult.nameEn.trim() : oneFetchedResult.nameFr.trim(),
          type: i18n.language.startsWith('en')
            ? oneFetchedResult.clientType?.nameEn
            : oneFetchedResult.clientType?.nameFr,
          isModifiableOrDeletable: oneFetchedResult.clientType?.isModifiableOrDeletable,
          hasUser: oneFetchedResult.hasUser,
          status: oneFetchedResult.isActive
            ? t('dashclients.clientDetails.status_active')
            : t('dashclients.clientDetails.status_inactive'),
        }))
        .sort(getComparator(order, orderBy))
    );
  }, [fetchedResults, i18n.language, order, orderBy, setResultsCsv, t]);

  const handleRequestSort = (property: keyof DashClientResult) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof DashClientResult) => () => {
    handleRequestSort(property);
  };

  const handleOnClickDelete = (client: Client | undefined) => {
    if (client) {
      setSelectedClient(client);
      setOpenDeleteDialog(true);
    }
  };

  const handleOnClickDetails = (client: Client | undefined) => {
    if (client) {
      setSelectedClient(client);
      setIsLoading(true);
      DashClients.getClientDetails(client.id.toString(), ClientTypeEnum[client.clientType.id])
        .then((result) => {
          if (result) {
            result.sponsors.forEach((s) => (s.hasUser = result.hasEmployedUser));
          }
          setIsLoading(false);
          history.push({
            pathname: `/dash-client/${result.id}`,
            state: {
              ...state,
              from: '/dash-client',
              clientSearchRequest: clientSearchFormValue,
              clientToEdit: result,
              clientDetailsMode: ModeEnum.Update,
            } as ReactLocationState,
          });
        })
        .catch((error) => {
          handleError(error);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <TableContainer component={'div'} data-testid="tableContainer">
        <Table size="small" stickyHeader aria-label="table-dashClient" sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sx={{ width: `${headCell.width}%` }}
                  sortDirection={orderBy === headCell.id ? order : false}
                  data-testid={`headerCell-${headCell.id}`}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell align="right" sx={{ width: '25%' }} />
            </TableRow>
          </TableHead>
          <TableBody data-testid="tableBody">
            {fetchedResults
              .map((oneFetchedResult) => ({
                id: oneFetchedResult.id,
                code: oneFetchedResult.code ? oneFetchedResult.code : '',
                name: i18n.language.startsWith('en') ? oneFetchedResult.nameEn.trim() : oneFetchedResult.nameFr.trim(),
                type: i18n.language.startsWith('en')
                  ? oneFetchedResult.clientType?.nameEn
                  : oneFetchedResult.clientType?.nameFr,
                isModifiableOrDeletable: oneFetchedResult.clientType?.isModifiableOrDeletable,
                hasUser: oneFetchedResult.hasUser,
                status: oneFetchedResult.isActive
                  ? t('dashclients.clientDetails.status_active')
                  : t('dashclients.clientDetails.status_inactive'),
                isSponsor: oneFetchedResult.isSponsor,
                hasDriverReport: oneFetchedResult.hasDriverReport,
              }))
              .sort(getComparator(order, orderBy))
              .map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell
                    className={classes.tableCell}
                    sx={{ width: '10%' }}
                    data-testid={`tableCell-clientType-${index}`}
                  >
                    <Tooltip title={row.code ? row.code : ''} enterTouchDelay={0}>
                      <Typography className={classes.textCell}>{row.code}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.tableCell}
                    sx={{ width: '45%' }}
                    data-testid={`tableCell-clientName-${index}`}
                  >
                    <Tooltip title={row.name} enterTouchDelay={0}>
                      <Typography className={classes.textCell}>{row.name}</Typography>
                    </Tooltip>
                  </TableCell>
                  {row.type && (
                    <TableCell
                      className={classes.tableCell}
                      sx={{ width: '20%' }}
                      data-testid={`tableCell-clientType-${index}`}
                    >
                      <Tooltip title={row.type} enterTouchDelay={0}>
                        <Typography className={classes.textCell}>{row.type}</Typography>
                      </Tooltip>
                    </TableCell>
                  )}
                  <TableCell
                    className={classes.tableCell}
                    sx={{ width: '10%' }}
                    data-testid={`tableCell-clientType-${index}`}
                  >
                    <Tooltip title={row.status} enterTouchDelay={0}>
                      <Typography className={classes.textCell}>{row.status}</Typography>
                    </Tooltip>
                  </TableCell>

                  <TableCell align="right" className={classes.tableCell} sx={{ width: '25%' }}>
                    <Grid container justifyContent="flex-end">
                      {row.type && row.isModifiableOrDeletable && (
                        <Grid item marginRight={4}>
                          <Button
                            className={classes.buttonText}
                            variant="text"
                            color="primary"
                            id={`btnDeleteClient${index}`}
                            endIcon={<ArrowRightIcon />}
                            data-testid={`btnDeleteClient${index}`}
                            onClick={() => handleOnClickDelete(fetchedResults.find((r) => r.id === row.id))}
                            disabled={row.hasUser || row.isSponsor || row.hasDriverReport}
                          >
                            {t('dashclients.delete')}
                          </Button>
                        </Grid>
                      )}
                      <Grid item>
                        <Button
                          className={classes.buttonText}
                          variant="text"
                          color="primary"
                          id={`btnViewClientDetails${index}`}
                          endIcon={<ArrowRightIcon />}
                          data-testid={`btnViewClientDetails${index}`}
                          onClick={() => handleOnClickDetails(fetchedResults.find((r) => r.id === row.id))}
                        >
                          {t('dashclients.details')}
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Loader open={isLoading} />
      <Dialog
        open={openDeleteDialog}
        fullWidth
        data-testid="DeleteClientDialog"
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setOpenDeleteDialog(false);
          }
        }}
      >
        <DeleteClientDialog
          clientToDelete={selectedClient!}
          handleSearch={handleSearch}
          setOpen={setOpenDeleteDialog}
        />
      </Dialog>
    </>
  );
};

export default DashClientResults;
