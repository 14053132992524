export interface OrganizationSponsor {
  id: number;
  nameEn: string;
  nameFr: string;
}

export const OrganizationSponsorFactory = (props: OrganizationSponsor): OrganizationSponsor => ({
  id: props.id,
  nameEn: props.nameEn,
  nameFr: props.nameFr,
});

export interface ReportingCompanySponsor {
  id: number;
  nameEn: string;
  nameFr: string;
}

export const ReportingCompanySponsorFactory = (props: ReportingCompanySponsor): ReportingCompanySponsor => ({
  id: props.id,
  nameEn: props.nameEn,
  nameFr: props.nameFr,
});

export interface Sponsor {
  id: number;
  clientId: number;
  organization: OrganizationSponsor;
  reportingCompany?: ReportingCompanySponsor;
  hasUser: boolean;
}

export const SponsorFactory = (props: Sponsor): Sponsor => ({
  id: props.id,
  clientId: props.clientId,
  organization: props.organization,
  reportingCompany: props.reportingCompany,
  hasUser: props.hasUser,
});

export interface ClientType {
  id: number;
  nameEn: string;
  nameFr: string;
  canHaveSponsors: boolean;
  isModifiableOrDeletable: boolean;
}

export const ClientTypeFactory = (props: ClientType): ClientType => ({
  id: props.id,
  nameEn: props.nameEn,
  nameFr: props.nameFr,
  canHaveSponsors: props.canHaveSponsors,
  isModifiableOrDeletable: props.isModifiableOrDeletable,
});

export interface Client {
  id: number;
  code?: string;
  nameEn: string;
  nameFr: string;
  clientType: ClientType;
  hasUser: boolean;
  hasEmployedUser: boolean;
  sponsors: Array<Sponsor>;
  isActive: boolean;
  isSponsor: boolean;
  hasDriverReport: boolean;
}

export const ClientFactory = (props: Client): Client => ({
  id: props.id,
  nameEn: props.nameEn,
  nameFr: props.nameFr,
  clientType: props.clientType,
  hasUser: props.hasUser,
  hasEmployedUser: props.hasEmployedUser,
  sponsors: props.sponsors,
  isActive: props.isActive,
  isSponsor: props.isSponsor,
  hasDriverReport: props.hasDriverReport,
});

export interface ClientSearchFormValue {
  clientId: string | null;
  clientName: string | null;
  clientTypeId: number | null;
  status: number | null;
}

export interface ClientSearchRequest {
  clientId: string | null;
  clientName: string | null;
  clientTypeId: number | null;
  isActive: boolean | null;
}

export interface DashClientResult {
  code: string;
  name: string;
  type: string;
  status: string;
}
