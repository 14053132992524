import { FC, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { subDays } from 'date-fns';
import enLocale from 'date-fns/locale/en-CA';
import frLocale from 'date-fns/locale/fr';

import { NinetyDaysReportAxios, ReportingCompanies, Units } from '../../axios';
import DashCustomInput from '../../Components/DashCustomInput';
import ExportAsyncCsv from '../../Components/ExportAsyncCsv';
import Loader from '../../Components/Loader';
import Message from '../../Components/Message';
import { DATE_FORMAT, DATE_MASK, PAGINATION_PAGESIZE } from '../../Helpers/Constants';
import { formatDateToLocalTime, getDateFromNow, isValidDate } from '../../Helpers/DateHelper';
import { sortOrganizations, sortRptCompanies, sortUnits } from '../../Helpers/SortHelpers';
import { userFullNameToString } from '../../Helpers/UserHelper';
import useAutofocus from '../../Hooks/UseAutofocus';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import useIbcOrThirdPartySelected from '../../Hooks/UseIbcOrThirdPartySelected';
import { GetAllOrganizations, getOrganizationsByUserAccess } from '../../Slices/OrganizationSlice';
import { getConnectedUser } from '../../Slices/UserSlice';
import theme from '../../theme';
import {
  DataCorrection,
  Functions,
  NinetyDaysReportStatus,
  Organization,
  ReactLocationState,
  Report90DaysRequest,
  Report90DaysResponse,
  ReportingCompany,
  Unit,
} from '../../Types';
import { PaginationQuery } from '../../Types/PaginationQuery';
import { Report90DaysResponseCsvType } from '../../Types/Report90Days';
import Report90DResults from './Report90DResults';

const Report90DSearch: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const { pathname, state } = useLocation<ReactLocationState>();
  const savedSearchParams = state && state.ninetyDaysSearchParams;
  const savedPageNo = state && state.ninetyDaysPageNo;

  const user = useSelector((state: { user: any }) => getConnectedUser(state));

  const csvLink = useRef<any>(null);

  const organizations = useSelector((state: { organization: any }) => GetAllOrganizations(state));

  const [isLoadingRpt, setIsLoadingRpt] = useState<boolean>(false);
  const [fetchedRptCompanies, setFetchedRptCompanies] = useState<Array<ReportingCompany> | null>(null);

  const [units, setUnits] = useState<Array<Unit>>([]);
  const [isLoadingUnits, setIsLoadingUnits] = useState<boolean>(false);

  const [pageNo, setPageNo] = useState<number | null>(state && state.ninetyDaysPageNo ? state.ninetyDaysPageNo : null);
  const [resultCount, setResultCount] = useState<number>(
    state && state.ninetyDaysSearchResultsCount ? state.ninetyDaysSearchResultsCount : 0
  );
  const [results, setResults] = useState<Array<Report90DaysResponse> | null>(
    state && state.ninetyDaysSearchResults ? state.ninetyDaysSearchResults : null
  );
  const [resultsCsv, setResultsCsv] = useState<Array<Report90DaysResponseCsvType> | null>(null);
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);

  // unit is outside formValues because it requires a default value after a fetch
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>(
    savedSearchParams && savedSearchParams.unitId ? savedSearchParams.unitId : null
  );

  const todayDate = new Date();
  const ninetyDaysReportStatus = NinetyDaysReportStatus.all;

  const [formValues, setFormValues] = useState<Omit<Report90DaysRequest, 'unitId'>>({
    organizationId: savedSearchParams ? savedSearchParams.organizationId : null,
    reportingCompanyId: savedSearchParams ? savedSearchParams.reportingCompanyId : null,
    reportRequestor: savedSearchParams ? savedSearchParams.reportRequestor || '' : userFullNameToString(user),
    fromDate: savedSearchParams ? savedSearchParams.fromDate : new Date(subDays(todayDate, 90)),
    toDate: savedSearchParams ? savedSearchParams.toDate : todayDate,
    ninetyDaysReportStatus: savedSearchParams ? savedSearchParams.ninetyDaysReportStatus : ninetyDaysReportStatus,
  });

  const isIbcOrThirdPartySelected = useIbcOrThirdPartySelected(formValues.organizationId);

  const [requestParams, setRequestParams] = useState<Report90DaysRequest | null>(null);
  const [fileName, setFilename] = useState<string | undefined>();

  const [errorFromDate, setErrorFromDate] = useState<boolean>(false);
  const [errorToDate, setErrorToDate] = useState<boolean>(false);

  // CSV headers for the CSV export
  const csvHeaders = [
    {
      key: 'organization',
      label: t('ninetyReport.results.organization'),
    },
    {
      label: t('ninetyReport.results.company'),
      key: 'company',
    },
    {
      label: t('ninetyReport.results.requestor'),
      key: 'reportRequestor',
    },
    {
      label: t('ninetyReport.results.reportDate'),
      key: 'reportDate',
    },
    {
      label: t('ninetyReport.results.changeDate'),
      key: 'changeDate',
    },
    {
      label: t('ninetyReport.results.ref'),
      key: 'referenceNumber',
    },
    {
      label: t('ninetyReport.results.dln'),
      key: 'driverLicenseNumber',
    },
    {
      label: t('ninetyReport.results.changes'),
      key: 'changes',
    },
    {
      label: t('ninetyReport.results.reviewedBy'),
      key: 'reviewedBy',
    },
  ];

  const organizationRef = useAutofocus();

  // Search request
  const searchRequestCallback = useCallback(() => {
    setIsLoadingResults(true);

    // Create the parameters and saved them in LocalStorage
    const params: Report90DaysRequest = {
      ...formValues,
      fromDate: formValues.fromDate
        ? new Date(
            formValues.fromDate.getFullYear(),
            formValues.fromDate.getMonth(),
            formValues.fromDate.getDate(),
            0,
            0,
            0,
            0
          )
        : null,
      toDate: formValues.toDate
        ? new Date(
            formValues.toDate.getFullYear(),
            formValues.toDate.getMonth(),
            formValues.toDate.getDate(),
            23,
            59,
            59,
            59
          )
        : null,
      unitId: selectedUnitId,
    };
    const paginationQuery: PaginationQuery = {
      pageSize: PAGINATION_PAGESIZE,
      pageNumber: (pageNo ?? 0) + 1,
    };

    NinetyDaysReportAxios.find(params, paginationQuery)
      .then((results) => {
        // temporary cast until count/data is removed
        const resultsTyped = results.data as Array<Report90DaysResponse>;
        setResults(resultsTyped);
        setResultCount(results.count);
        setIsLoadingResults(false);
        setRequestParams(params);
      })
      .catch((error) => {
        handleError(error);
        setIsLoadingResults(false);
      });
  }, [formValues, handleError, pageNo, selectedUnitId]);

  const fetchCSVData = () => {
    setIsLoadingResults(true);
    const params: Report90DaysRequest = {
      ...formValues,
      fromDate: formValues.fromDate
        ? new Date(
            formValues.fromDate.getFullYear(),
            formValues.fromDate.getMonth(),
            formValues.fromDate.getDate(),
            0,
            0,
            0,
            0
          )
        : null,
      toDate: formValues.toDate
        ? new Date(
            formValues.toDate.getFullYear(),
            formValues.toDate.getMonth(),
            formValues.toDate.getDate(),
            23,
            59,
            59,
            59
          )
        : null,
      unitId: selectedUnitId,
    };

    NinetyDaysReportAxios.find(params)
      .then((results) => {
        const resultsTyped = results.data as Array<Report90DaysResponse>;
        setResultsCsv(
          resultsTyped.map((oneFetchedResult) => ({
            organization: i18n.language.startsWith('en')
              ? oneFetchedResult.organization?.nameEn
                ? oneFetchedResult.organization?.nameEn
                : ''
              : oneFetchedResult.organization?.nameFr
              ? oneFetchedResult.organization?.nameFr
              : '',
            company:
              oneFetchedResult.organization && oneFetchedResult.company
                ? i18n.language.startsWith('en')
                  ? oneFetchedResult.company?.nameEn
                    ? oneFetchedResult.company?.nameEn
                    : ''
                  : oneFetchedResult.company?.nameFr
                  ? oneFetchedResult.company?.nameFr
                  : ''
                : i18n.language.startsWith('en')
                ? oneFetchedResult.organization?.nameEn
                  ? oneFetchedResult.organization?.nameEn
                  : ''
                : oneFetchedResult.organization?.nameFr
                ? oneFetchedResult.organization?.nameFr
                : '',
            reportRequestor: oneFetchedResult.reportRequestor,
            reportDate: formatDateToLocalTime(oneFetchedResult.reportDate, true),
            changeDate: formatDateToLocalTime(oneFetchedResult.changeDate, false),
            referenceNumber: oneFetchedResult.referenceNumber,
            driverLicenseNumber: oneFetchedResult.driverLicenseNumber,
            changes: `${oneFetchedResult.changes
              .filter(
                (correction: DataCorrection, idx: number, correctionsList: DataCorrection[]) =>
                  correctionsList.findIndex((t) => t.id === correction.id) === idx
              )
              .map((correction) => (i18n.language.startsWith('en') ? `${correction.nameEn}` : `${correction.nameFr}`))
              .join(';')}`,
            reviewedBy: oneFetchedResult.reviewedBy,
          }))
        );
        setFilename(`${t('menu.90days')}-${getDateFromNow().replace(/[^a-zA-Z0-9]/g, '')}`);
        setIsLoadingResults(false);
        if (csvLink.current) csvLink.current.link.click(); // call csvLink
      })
      .catch((error) => {
        handleError(error);
        setIsLoadingResults(false);
      });
  };

  // Re-fetching results when coming back on the page
  useEffect(() => {
    if (savedSearchParams) {
      setIsLoadingResults(true);

      const paginationQuery: PaginationQuery = {
        pageSize: PAGINATION_PAGESIZE,
        pageNumber: (savedPageNo ?? 0) + 1,
      };

      NinetyDaysReportAxios.find(savedSearchParams, paginationQuery)
        .then((results) => {
          // temporary cast until count/data is removed
          const resultsTyped = results.data as Array<Report90DaysResponse>;
          setResults(resultsTyped);
          setResultCount(results.count);
          setIsLoadingResults(false);
          setRequestParams(savedSearchParams);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingResults(false);
        });
    }
  }, [dispatch, formValues, handleError, savedSearchParams, savedPageNo, t]);

  // re-fetch results for new page
  useEffect(() => {
    if (pageNo !== null) handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  // Reseting the state of the location object at render
  useEffect(() => {
    history.replace(pathname, { from: pathname });
  }, [history, pathname]);

  useEffect(() => {
    dispatch(getOrganizationsByUserAccess({ functionId: Functions.ninetyDaysReport }));
  }, [dispatch]);

  // Initialize selectedOrg if there is only one organizations available to the user
  useEffect(() => {
    if (organizations && organizations.length === 1) {
      setFormValues((prev) => {
        return { ...prev, organizationId: organizations[0].id };
      });
    }
  }, [organizations]);

  // Fetch Reporting Companies
  useEffect(() => {
    if (!isIbcOrThirdPartySelected && formValues.organizationId) {
      setIsLoadingRpt(true);
      ReportingCompanies.getByOrganization(Functions.reportHistory, formValues.organizationId)
        .then((results) => {
          setFetchedRptCompanies(results);
          setIsLoadingRpt(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingRpt(false);
        });
    }
  }, [dispatch, formValues.organizationId, handleError, isIbcOrThirdPartySelected, t]);

  // Loads the units
  useEffect(() => {
    if (formValues.organizationId && formValues.reportingCompanyId) {
      setIsLoadingUnits(true);
      Units.getUnitsByCompanyId(Functions.ninetyDaysReport, formValues.organizationId, formValues.reportingCompanyId)
        .then((results) => {
          setUnits(results);
          setIsLoadingUnits(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingUnits(false);
        });
    }
  }, [formValues.organizationId, formValues.reportingCompanyId, handleError]);

  const isFormValid = (): boolean => {
    let error = false;

    if (!isValidDate(formValues.fromDate)) {
      setErrorFromDate(true);
      error = true;
    } else {
      setErrorFromDate(false);
    }

    if (!isValidDate(formValues.toDate)) {
      setErrorToDate(true);
      error = true;
    } else {
      setErrorToDate(false);
    }

    if (error) {
      return false;
    }

    return true;
  };

  const handleSearch = () => {
    if (isFormValid()) searchRequestCallback();
  };

  const handleAutocompleteChange = (name: string, value: number | null) => {
    if (name === 'organizationId') {
      setFormValues({
        ...formValues,
        organizationId: value,
        reportingCompanyId: null,
      });
      setSelectedUnitId(null);
    } else if (name === 'reportingCompanyId') {
      setFormValues({ ...formValues, reportingCompanyId: value });
      setSelectedUnitId(null);
    }
  };

  const handleAutocompleteUnitChange = (value: number | null) => {
    setSelectedUnitId(value);
  };

  const handleDatePickerChange = (fieldName: string, newValue: Date | null) => {
    setFormValues({ ...formValues, [fieldName]: newValue });
  };

  const resetFormValues = () => {
    setFormValues({
      organizationId: organizations.length === 1 ? organizations[0].id : null,
      reportingCompanyId: null,
      reportRequestor: userFullNameToString(user),
      fromDate: new Date(subDays(todayDate, 90)),
      toDate: todayDate,
      ninetyDaysReportStatus: NinetyDaysReportStatus.all,
    });
    setSelectedUnitId(units.length === 1 ? units[0].id : null);
    setResults(null);
    setResultCount(0);
    setUnits([]);
    setFetchedRptCompanies(null);

    // clear errors
    setErrorFromDate(false);
    setErrorToDate(false);
  };

  const handleClearFields = () => {
    resetFormValues();
  };

  const handleInputChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (pageNo === 0) {
      handleSearch();
    } else {
      setPageNo(0);
    }
  };

  const report90DResultsTable = useRef(null);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPageNo(newPage);
    if (report90DResultsTable.current !== null) {
      window.scrollTo({
        top: report90DResultsTable?.current['offsetTop'] - 108,
        behavior: 'smooth',
      });
    }
  }, []);

  const sort = useCallback(
    (a: any, b: any, type: string): number => {
      switch (type) {
        case 'organization':
          return sortOrganizations(a, b, i18n);
        case 'reportingcompany':
          return sortRptCompanies(a, b, i18n);
        default:
          return 0;
      }
    },
    [i18n]
  );

  return (
    <>
      <Card elevation={3} data-testid="ninetyDaysSearchCard">
        <CardContent>
          <form onSubmit={handleFormSubmit}>
            <Grid container>
              <Grid item container spacing={2} padding={2} xs={12}>
                {/* Field column */}
                <Grid item container spacing={1} md={10} lg={10} xl={10}>
                  <Grid item container md={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      noOptionsText={t('search.noOption')}
                      value={
                        formValues.organizationId !== null
                          ? organizations.find((oneOrg: Organization) => oneOrg.id === formValues.organizationId)
                          : null
                      }
                      id="organizationId"
                      data-testid="organizationId"
                      options={organizations.sort((a, b) => sort(a, b, 'organization'))}
                      getOptionLabel={(option: Organization) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          label={t('reportHistory.organization')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          placeholder={t('userAdmin.userAdmin.all')}
                          InputProps={{
                            ...params.InputProps,
                            inputRef: organizationRef,
                          }}
                        />
                      )}
                      onChange={(_, value) =>
                        handleAutocompleteChange('organizationId', value && value.id ? value.id : null)
                      }
                    />
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    {!isIbcOrThirdPartySelected ? (
                      <Autocomplete
                        fullWidth
                        loading={isLoadingRpt}
                        loadingText={t('loading')}
                        noOptionsText={t('search.noOption')}
                        value={
                          formValues.reportingCompanyId !== null && fetchedRptCompanies
                            ? fetchedRptCompanies.find((oneRpt) => oneRpt.id === formValues.reportingCompanyId)
                            : null
                        }
                        id="reportingCompanyId"
                        data-testid="reportingCompanyId"
                        options={
                          fetchedRptCompanies ? fetchedRptCompanies.sort((a, b) => sort(a, b, 'reportingcompany')) : []
                        }
                        getOptionLabel={(option: ReportingCompany) =>
                          i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                        }
                        renderInput={(params) => (
                          <DashCustomInput
                            {...params}
                            label={t('reportHistory.companyName')}
                            labelGridSize={3}
                            fieldGridSize={8}
                            variant="outlined"
                            color="secondary"
                            placeholder={t('userAdmin.userAdmin.all')}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isLoadingRpt ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                      sx={{ marginRight: theme.spacing(4) }}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                        onChange={(_, value) =>
                          handleAutocompleteChange('reportingCompanyId', value && value.id ? value.id : null)
                        }
                        disabled={isIbcOrThirdPartySelected || !formValues.organizationId}
                      />
                    ) : (
                      /* Dummy field for company when IBC or 3rd party */
                      <DashCustomInput
                        fullWidth
                        id="companyHolderIbc3rdPart"
                        value={
                          formValues.organizationId
                            ? i18n.language.startsWith('en')
                              ? organizations.find((oneOrg) => oneOrg.id === formValues.organizationId)?.nameEn
                              : organizations.find((oneOrg) => oneOrg.id === formValues.organizationId)?.nameFr
                            : ''
                        }
                        label={t('reportHistory.companyName')}
                        labelGridSize={3}
                        fieldGridSize={8}
                        variant="outlined"
                        color="secondary"
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      loading={isLoadingUnits}
                      loadingText={t('loading')}
                      noOptionsText={t('search.noOption')}
                      value={
                        selectedUnitId !== null && units.length > 0
                          ? units.find((oneUnit) => oneUnit.id === selectedUnitId)
                          : null
                      }
                      id="unitId"
                      data-testid="unitId"
                      options={units.sort((a: Unit, b: Unit) => sortUnits(a, b))}
                      getOptionLabel={(option: Unit) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          label={t('ninetyReport.unit')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          placeholder={units.length < 1 ? undefined : t('userAdmin.userAdmin.all')}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingUnits ? (
                                  <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      onChange={(_, value) => handleAutocompleteUnitChange(value && value.id ? value.id : null)}
                      disabled={!formValues.reportingCompanyId || units.length < 1}
                    />
                  </Grid>
                  <Grid item xs={12} lg={11} xl={8}>
                    <DashCustomInput
                      fullWidth
                      id="reportRequestor"
                      value={formValues.reportRequestor}
                      label={t('ninetyReport.requestor')}
                      labelGridSize={3}
                      fieldGridSize={5}
                      variant="outlined"
                      color="secondary"
                      onChange={(e) => handleInputChange(e)}
                      inputProps={{
                        'data-testid': 'reportRequestor',
                        name: 'reportRequestor',
                      }}
                    />
                  </Grid>
                  {/* Datepickers */}
                  <Grid container item xs={12} lg={11} xl={8}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item xs={3} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
                        <Typography
                          // sx={{
                          //   fontWeight: 700,
                          //   textAlign: 'end',
                          // }}
                          sx={{ fontWeight: 700, display: 'inline' }}
                        >
                          {t('ninetyReport.changeDate')}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} paddingRight={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={i18n.language.startsWith('en') ? enLocale : frLocale}
                        >
                          <DatePicker
                            clearable
                            disableFuture
                            mask={DATE_MASK}
                            inputFormat={DATE_FORMAT}
                            value={formValues.fromDate}
                            onChange={(newValue) => handleDatePickerChange('fromDate', newValue)}
                            renderInput={(params) => (
                              <DashCustomInput
                                {...params}
                                fullWidth
                                label={undefined}
                                // label={t('ninetyReport.changeDate')}
                                fieldGridSize={12}
                                inputProps={{
                                  ...params.inputProps,
                                  'data-testid': 'fromDatePicker',
                                  placeholder: t('datepickerPlaceholderFrom').toString(),
                                }}
                                variant="outlined"
                                color="secondary"
                                error={errorFromDate}
                                helperText={(errorFromDate && t('reportHistory.invalidDate')) || (errorToDate && ' ')}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={3} paddingLeft={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={i18n.language.startsWith('en') ? enLocale : frLocale}
                        >
                          <DatePicker
                            clearable
                            disableFuture
                            mask={DATE_MASK}
                            inputFormat={DATE_FORMAT}
                            value={formValues.toDate}
                            onChange={(newValue) => handleDatePickerChange('toDate', newValue)}
                            renderInput={(params) => (
                              <DashCustomInput
                                {...params}
                                fullWidth
                                label={undefined}
                                fieldGridSize={12}
                                inputProps={{
                                  ...params.inputProps,
                                  'data-testid': 'toDatePicker',
                                  placeholder: t('datepickerPlaceholderTo').toString(),
                                }}
                                variant="outlined"
                                color="secondary"
                                error={errorToDate}
                                helperText={(errorToDate && t('reportHistory.invalidDate')) || (errorFromDate && ' ')}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* END DatePicker */}
                  <Grid item container md={12} lg={11} xl={8}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item xs={3} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            textAlign: 'end',
                          }}
                        >
                          {t('ninetyReport.status')}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Select
                          fullWidth
                          id="status"
                          size="small"
                          value={formValues.ninetyDaysReportStatus}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              ninetyDaysReportStatus: e.target.value as NinetyDaysReportStatus,
                            })
                          }
                          data-testid="selectStatus"
                          sx={{
                            textAlign: 'left',
                          }}
                        >
                          <MenuItem value={0}>{t('ninetyReport.ninetyDaysReportStatus.all')}</MenuItem>
                          <MenuItem value={1}>{t('ninetyReport.ninetyDaysReportStatus.reviewed')}</MenuItem>
                          <MenuItem value={2}>{t('ninetyReport.ninetyDaysReportStatus.notreviewed')}</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Buttons */}
                  <Grid
                    item
                    container
                    alignItems="center"
                    wrap="nowrap"
                    xs={12}
                    lg={11}
                    xl={8}
                    sx={{ marginTop: theme.spacing(2) }}
                  >
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item container xs={3} sx={{ marginRight: theme.spacing(2) }} />
                      <Grid item container spacing={1} xs={6}>
                        <Grid item xs={5}>
                          <Button
                            fullWidth
                            variant="contained"
                            id="btnSearch"
                            type="submit"
                            data-testid="btnSearch"
                            disabled={isLoadingRpt || isLoadingUnits || isLoadingResults}
                          >
                            {t('userAdmin.lookup')}
                          </Button>
                        </Grid>
                        <Grid item xs={5}>
                          <Button
                            fullWidth
                            onClick={handleClearFields}
                            variant="contained"
                            color="secondary"
                            data-testid="clearFields"
                          >
                            {t('search.clearFields')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* End Buttons */}
                </Grid>

                {/* Export Button */}
                <Grid item container md={2} lg={2} xl={2} alignContent="flex-end" justifyContent="flex-end">
                  <Grid item md={12} lg={12} xl={9}>
                    <ExportAsyncCsv
                      csvLink={csvLink}
                      hasSearchResult={resultCount > 0}
                      csvData={resultsCsv ? resultsCsv : []}
                      csvHeaders={csvHeaders}
                      fileName={fileName}
                      onClick={() => fetchCSVData()}
                    />
                  </Grid>
                </Grid>
                {/* End Export Button */}
              </Grid>

              {results && results.length > 0 && (
                <Grid item container xs={12} padding={2} ref={report90DResultsTable}>
                  <Grid item container xs={12}>
                    <Report90DResults
                      requestParams={requestParams}
                      fetchedResults={results}
                      handleChangePage={handleChangePage}
                      count={resultCount}
                      pageNo={pageNo ?? 0}
                    />
                  </Grid>
                </Grid>
              )}
              {results && results.length === 0 && (
                <Grid item container xs={12} padding={2} justifyContent="center" marginTop={theme.spacing(2)}>
                  <Message message={t('ninetyReport.noResults')} severity="info" />
                </Grid>
              )}
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Loader open={isLoadingResults} />
    </>
  );
};

export default Report90DSearch;
