import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, SerializedError } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { Organizations } from '../axios';
import { Organization, OrganizationFactory } from '../Types';

const language = i18next.language;
// Adapter
const organizationAdapter = createEntityAdapter<Organization>({
  // Assume IDs are stored in a field id
  selectId: (organization) => organization.id,
  // Keep the "all IDs" array sorted based on acronym
  sortComparer: (a, b) => {
    if (language && language.startsWith('en')) {
      if (a.nameEn < b.nameEn) {
        return -1;
      }
      if (a.nameEn > b.nameEn) {
        return 1;
      }

      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }

      return 0;
    } else {
      if (a.nameFr > b.nameFr) {
        return 1;
      }
      if (a.nameFr < b.nameFr) {
        return -1;
      }
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    }
  },
});

// Selectors
// Get default selects that comes with entityAdapter
const organizationSelectors = organizationAdapter.getSelectors((state: { organization: any }) => state.organization);

// map selectors if you want to have them called differently
export const { selectId: getOrganizationById } = organizationAdapter;

export const getOrganizationByCode = createSelector(
  [
    // First input selector: all organizations
    organizationSelectors.selectAll,
    // Second input selector: organization value
    (state: any, code: string) => code,
  ],
  // Output selector: receives both values
  (organizations: Array<Organization>, code: string) => {
    // Return orga based on code
    return organizations.filter((oneOrganization) => {
      return oneOrganization.code === code;
    });
  }
);

export const GetAllOrganizations = createSelector(
  [organizationSelectors.selectAll],
  (organizations: Array<Organization>) => {
    return organizations;
  }
);

//Test helper
export const CreateOrganizationTestState = (isPending: boolean, errorCode = '', errorMessage = '') => {
  const error: SerializedError | null = errorMessage !== '' ? { code: errorCode, message: errorMessage } : null;

  const organizations = [
    OrganizationFactory(1, 'IBC', 'IBCEn', 'IBCFr', '12345'),
    OrganizationFactory(2, 'Carrier', 'CarrierEn', 'CarrierFr', '67890'),
    OrganizationFactory(3, 'ThirdParty', 'ThirdPartyEn', 'ThirdPartyFr', '23456'),
  ];

  const organizationState = organizationAdapter.getInitialState({ isPending, error });
  organizations.forEach((organization) => {
    organizationState.ids.push(organization.id);
    organizationState.entities[organization.id] = organization;
  });

  return { organization: organizationState };
};

// extra reducers - async thunk
export const getOrganizations = createAsyncThunk(
  '/organizations',
  async (params?: { includeOnlyThirdPartiesOfOtherType?: boolean }) => {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      // testing - return default state
      return [
        OrganizationFactory(1, 'IBC', 'IBCEn', 'IBCFr', '12345'),
        OrganizationFactory(2, 'Carrier', 'CarrierEn', 'CarrierFr', '67890'),
        OrganizationFactory(3, 'ThirdParty', 'ThirdPartyEn', 'ThirdPartyFr', '23456'),
      ];
    } else {
      const result = await Organizations.list(params);

      if (result) {
        return result;
      } else {
        return [];
      }
    }
  }
);

export const getOrganizationsByUserAccess = createAsyncThunk(
  '/organizations/get',
  async (payload: { functionId: number }) => {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      // testing - return default state
      return [
        OrganizationFactory(1, 'IBC', 'IBCEn', 'IBCFr', '12345'),
        OrganizationFactory(2, 'Carrier', 'CarrierEn', 'CarrierFr', '67890'),
        OrganizationFactory(3, 'ThirdParty', 'ThirdPartyEn', 'ThirdPartyFr', '23456'),
      ];
    } else {
      const result = await Organizations.get(payload.functionId);
      if (result) {
        return result;
      } else {
        return [];
      }
    }
  }
);

// Slice
const initialState: { isPending: boolean; error: SerializedError | null } = { isPending: false, error: null };

const organizationSlice = createSlice({
  name: 'organization',
  initialState: organizationAdapter.getInitialState(initialState),
  reducers: {
    removeError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganizations.pending, (state) => {
      if (state.isPending === false) {
        state.isPending = true;
      }
    });
    builder.addCase(getOrganizations.fulfilled, (state, { payload: organizations }) => {
      state.isPending = false;
      if (organizations) {
        organizationAdapter.removeAll(state);
        organizationAdapter.addMany(state, organizations);
      }
    });
    builder.addCase(getOrganizations.rejected, (state, action) => {
      state.isPending = false;
      if (action.error.code === '') {
        // Create new state error and pass it to state.error
      }
      state.error = action.error;
    });

    builder.addCase(getOrganizationsByUserAccess.pending, (state) => {
      organizationAdapter.removeAll(state);
      if (state.isPending === false) {
        state.isPending = true;
      }
    });
    builder.addCase(getOrganizationsByUserAccess.fulfilled, (state, { payload: organizations }) => {
      state.isPending = false;
      if (organizations) {
        organizationAdapter.removeAll(state);
        organizationAdapter.addMany(state, organizations);
      }
    });
    builder.addCase(getOrganizationsByUserAccess.rejected, (state, action) => {
      state.isPending = false;
      if (action.error.code === '') {
        // Create new state error and pass it to state.error
      }
      state.error = action.error;
    });
  },
});

// Action creators are generated for each case reducer function
export const { removeError } = organizationSlice.actions;

export default organizationSlice.reducer;
