import { CancelToken } from 'axios';

import { requests } from '../axios';
import { Report90DaysRequest, Response } from '../Types';
import { PaginationQuery } from '../Types/PaginationQuery';
import { Report90DaysUnreadCountResponse } from '../Types/Report90Days';

export const find = (params: Report90DaysRequest, paginationQuery?: PaginationQuery): Promise<Response> => {
  let query = '/DriverReports/NinetyDaysReportSearch';
  if (paginationQuery) {
    query += `?PageSize=${paginationQuery.pageSize}&PageNumber=${paginationQuery.pageNumber}`;
  }
  return requests.post(query, params);
};

export const getUnreadCount = (CancelToken?: CancelToken): Promise<Report90DaysUnreadCountResponse> => {
  return requests.get('/DriverReports/NinetyDaysReportUnreadCount', {}, CancelToken);
};
