import {
  AppFunction,
  DriverReportOption,
  Organization,
  ReportHistoryResponseItem,
  ReportingCompany,
  Sponsor,
  Unit,
  UsageReportResponse,
  UserAccessReportResponse,
  UserFunctionCompany,
  VehicleClaim,
  VehiclePolicy,
} from '../Types';

export const compareABstrings = (a: string, b: string): number => {
  if (a < b) {
    return -1;
  } else if (a === b) {
    return 0;
  } else if (a > b) {
    return 1;
  } else return 0;
};

const compareYearsDESC = (a: DriverReportOption, b: DriverReportOption, maxYearsLast?: boolean): number => {
  if (a.yearsOfData && b.yearsOfData && a.yearsOfData < b.yearsOfData) {
    return 1;
  } else if (a.yearsOfData && b.yearsOfData && a.yearsOfData === b.yearsOfData) {
    return 0;
  } else if (a.yearsOfData && b.yearsOfData && a.yearsOfData > b.yearsOfData) {
    return -1;
  } else if (a.yearsOfData && !b.yearsOfData) {
    return maxYearsLast ? -1 : 1;
  } else if (!a.yearsOfData && b.yearsOfData) {
    return maxYearsLast ? 1 : -1;
  } else return 0;
};

const compareABstringsWithYears = (
  a: DriverReportOption,
  aString: string,
  b: DriverReportOption,
  bString: string,
  maxYearsLast?: boolean
): number => {
  if (aString < bString) {
    return -1;
  } else if (aString === bString) {
    return compareYearsDESC(a, b, maxYearsLast);
  } else if (aString > bString) {
    return 1;
  } else return 0;
};

export const sortDriverReportOptions = (
  a: DriverReportOption,
  b: DriverReportOption,
  i18n: any,
  organizations: Organization[],
  rptCompanies: ReportingCompany[] | null,
  maxYearsLast?: boolean
): number => {
  // If both has reporting companies
  if (rptCompanies && rptCompanies.length > 0 && a.reportingCompanyId && b.reportingCompanyId) {
    // Case language is english
    if (i18n.language.startsWith('en')) {
      const nameCompA = rptCompanies.find((o) => o.id === a.reportingCompanyId)!.nameEn?.toLocaleLowerCase();
      const nameCompB = rptCompanies.find((o) => o.id === b.reportingCompanyId)!.nameEn?.toLocaleLowerCase();
      const nameCompAsubtring = nameCompA.substring(nameCompA.indexOf('-') + 2);
      const nameCompBsubtring = nameCompB.substring(nameCompB.indexOf('-') + 2);

      return compareABstringsWithYears(a, nameCompAsubtring, b, nameCompBsubtring, maxYearsLast);
    } else {
      // Case user is in french
      const nameCompA = rptCompanies.find((o) => o.id === a.reportingCompanyId)!.nameFr.toLocaleLowerCase();
      const nameCompB = rptCompanies.find((o) => o.id === b.reportingCompanyId)!.nameFr.toLocaleLowerCase();
      const nameCompAsubtring = nameCompA.substring(nameCompA.indexOf('-') + 2);
      const nameCompBsubtring = nameCompB.substring(nameCompB.indexOf('-') + 2);

      return compareABstringsWithYears(a, nameCompAsubtring, b, nameCompBsubtring, maxYearsLast);
    }
  } else if (rptCompanies && rptCompanies.length > 0 && a.reportingCompanyId && !b.reportingCompanyId) {
    // Case a has a company and b has only a org
    if (i18n.language.startsWith('en')) {
      const nameCompA = rptCompanies.find((o) => o.id === a.reportingCompanyId)!.nameEn.toLocaleLowerCase();
      const nameOrgB = organizations.find((o) => o.id === b.organizationId)!.nameEn.toLocaleLowerCase();
      const nameCompAsubtring = nameCompA.substring(nameCompA.indexOf('-') + 2);

      return compareABstrings(nameCompAsubtring, nameOrgB);
    } else {
      const nameCompA = rptCompanies.find((o) => o.id === a.reportingCompanyId)!.nameFr.toLocaleLowerCase();
      const nameOrgB = organizations.find((o) => o.id === b.organizationId)!.nameFr.toLocaleLowerCase();
      const nameCompAsubtring = nameCompA.substring(nameCompA.indexOf('-') + 2);

      return compareABstrings(nameCompAsubtring, nameOrgB);
    }
  } else if (rptCompanies && rptCompanies.length > 0 && !a.reportingCompanyId && b.reportingCompanyId) {
    // Case a only has an org and b a company
    if (i18n.language.startsWith('en')) {
      const nameCompB = rptCompanies.find((o) => o.id === b.reportingCompanyId)!.nameEn.toLocaleLowerCase();
      const nameOrgA = organizations.find((o) => o.id === a.organizationId)!.nameEn.toLocaleLowerCase();
      const nameCompBsubtring = nameCompB.substring(nameCompB.indexOf('-') + 2);

      return compareABstrings(nameOrgA, nameCompBsubtring);
    } else {
      const nameCompB = rptCompanies.find((o) => o.id === b.reportingCompanyId)!.nameFr.toLocaleLowerCase();
      const nameOrgA = organizations.find((o) => o.id === a.organizationId)!.nameFr.toLocaleLowerCase();
      const nameCompBsubtring = nameCompB.substring(nameCompB.indexOf('-') + 2);

      return compareABstrings(nameOrgA, nameCompBsubtring);
    }
  } else if (!a.reportingCompanyId && !b.reportingCompanyId) {
    // case both only got orgs
    if (i18n.language.startsWith('en')) {
      const nameOrgA = organizations.find((o) => o.id === a.organizationId)!.nameEn.toLocaleLowerCase();
      const nameOrgB = organizations.find((o) => o.id === b.organizationId)!.nameEn.toLocaleLowerCase();

      return compareABstringsWithYears(a, nameOrgA, b, nameOrgB, maxYearsLast);
    } else {
      const nameOrgA = organizations.find((o) => o.id === a.organizationId)!.nameFr.toLocaleLowerCase();
      const nameOrgB = organizations.find((o) => o.id === b.organizationId)!.nameFr.toLocaleLowerCase();

      return compareABstringsWithYears(a, nameOrgA, b, nameOrgB, maxYearsLast);
    }
  } else return 0;
};

export const sortOrganizations = (a: Organization, b: Organization, i18n: any): number => {
  if (i18n.language.startsWith('en')) {
    const nameOrgA =
      a.organizationType === 'Carrier'
        ? a.nameEn.substring(a.nameEn.indexOf('-') + 2).toLocaleLowerCase()
        : a.nameEn.toLocaleLowerCase();
    const nameOrgB =
      b.organizationType === 'Carrier'
        ? b.nameEn.substring(b.nameEn.indexOf('-') + 2).toLocaleLowerCase()
        : b.nameEn.toLocaleLowerCase();

    if (nameOrgA > nameOrgB) {
      return 1;
    }
    if (nameOrgA < nameOrgB) {
      return -1;
    }
    return 0;
  } else {
    const nameOrgA =
      a.organizationType === 'Carrier'
        ? a.nameFr.substring(a.nameFr.indexOf('-') + 2).toLocaleLowerCase()
        : a.nameFr.toLocaleLowerCase();
    const nameOrgB =
      b.organizationType === 'Carrier'
        ? b.nameFr.substring(b.nameFr.indexOf('-') + 2).toLocaleLowerCase()
        : b.nameFr.toLocaleLowerCase();
    if (nameOrgA > nameOrgB) {
      return 1;
    }
    if (nameOrgA < nameOrgB) {
      return -1;
    }
    return 0;
  }
};

export const sortRptCompanies = (a: ReportingCompany, b: ReportingCompany, i18n: any): number => {
  if (i18n.language.startsWith('en')) {
    const nameRptCompA = a.nameEn.substring(a.nameEn.indexOf('-') + 2).toLocaleLowerCase();
    const nameRptCompB = b.nameEn.substring(b.nameEn.indexOf('-') + 2).toLocaleLowerCase();

    if (nameRptCompA > nameRptCompB) {
      return 1;
    }
    if (nameRptCompA < nameRptCompB) {
      return -1;
    }
    return 0;
  } else {
    const nameRptCompA = a.nameFr.substring(a.nameFr.indexOf('-') + 2).toLocaleLowerCase();
    const nameRptCompB = b.nameFr.substring(b.nameFr.indexOf('-') + 2).toLocaleLowerCase();
    if (nameRptCompA > nameRptCompB) {
      return 1;
    }
    if (nameRptCompA < nameRptCompB) {
      return -1;
    }
    return 0;
  }
};

export const sortUserFunctionCompanies = (a: UserFunctionCompany, b: UserFunctionCompany, i18n: any): number => {
  if (i18n.language.startsWith('en')) {
    const nameUFCompA = a.reportingCompanyId
      ? a.nameEn.substring(a.nameEn.indexOf('-') + 2).toLocaleLowerCase()
      : a.nameEn.toLocaleLowerCase();
    const nameUFCompB = b.reportingCompanyId
      ? b.nameEn.substring(b.nameEn.indexOf('-') + 2).toLocaleLowerCase()
      : b.nameEn.toLocaleLowerCase();

    if (nameUFCompA > nameUFCompB) {
      return 1;
    }
    if (nameUFCompA < nameUFCompB) {
      return -1;
    }
    return 0;
  } else {
    const nameUFCompA = a.reportingCompanyId
      ? a.nameFr.substring(a.nameFr.indexOf('-') + 2).toLocaleLowerCase()
      : a.nameFr.toLocaleLowerCase();
    const nameUFCompB = a.reportingCompanyId
      ? b.nameFr.substring(b.nameFr.indexOf('-') + 2).toLocaleLowerCase()
      : a.nameFr.toLocaleLowerCase();
    if (nameUFCompA > nameUFCompB) {
      return 1;
    }
    if (nameUFCompA < nameUFCompB) {
      return -1;
    }
    return 0;
  }
};
export const sortUnits = (a: Unit, b: Unit): number => {
  const nameUnitA = a.nameEn.toLocaleLowerCase();
  const nameUnitB = b.nameEn.toLocaleLowerCase();

  if (nameUnitA > nameUnitB) {
    return 1;
  }
  if (nameUnitA < nameUnitB) {
    return -1;
  }
  return 0;
};

const compareABstringWithUserIds = (aUserId: string, aString: string, bUserId: string, bString: string): number => {
  if (aString < bString) {
    return -1;
  } else if (aString === bString) {
    if (aUserId < bUserId) {
      return -1;
    } else if (aUserId === bUserId) {
      return 0;
    } else {
      return 1;
    }
  } else {
    return 1;
  }
};

export const sortUsageReportResponse = (a: UsageReportResponse, b: UsageReportResponse, i18n: any): number => {
  if (!a.reportingCompany && !b.reportingCompany) {
    if (i18n.language.startsWith('en')) {
      const nameOrgA = a.organization.nameEn.toLocaleLowerCase();
      const nameOrgB = b.organization.nameEn.toLocaleLowerCase();

      return compareABstringWithUserIds(a.userId, nameOrgA, b.userId, nameOrgB);
    } else {
      const nameOrgA = a.organization.nameFr.toLocaleLowerCase();
      const nameOrgB = b.organization.nameFr.toLocaleLowerCase();

      return compareABstringWithUserIds(a.userId, nameOrgA, b.userId, nameOrgB);
    }
  } else if (a.reportingCompany && !b.reportingCompany) {
    return -1;
  } else if (!a.reportingCompany && b.reportingCompany) {
    return 1;
  } else if (a.reportingCompany && b.reportingCompany) {
    if (i18n.language.startsWith('en')) {
      const nameCompAsubtring = a.reportingCompany.nameEn;
      const nameCompBsubtring = b.reportingCompany.nameEn;

      return compareABstringWithUserIds(a.userId, nameCompAsubtring, b.userId, nameCompBsubtring);
    } else {
      const nameCompAsubtring = a.reportingCompany.nameFr;
      const nameCompBsubtring = b.reportingCompany.nameFr;

      return compareABstringWithUserIds(a.userId, nameCompAsubtring, b.userId, nameCompBsubtring);
    }
  } else {
    return 0;
  }
};

export const sortUserAccessReportResponse = (
  a: UserAccessReportResponse,
  b: UserAccessReportResponse,
  i18n: any
): number => {
  if (!a.reportingCompany && !b.reportingCompany && a.organization && b.organization) {
    if (i18n.language.startsWith('en')) {
      const nameOrgA = a.organization.nameEn.toLocaleLowerCase();
      const nameOrgB = b.organization.nameEn.toLocaleLowerCase();

      return compareABstringWithUserIds(a.userId, nameOrgA, b.userId, nameOrgB);
    } else {
      const nameOrgA = a.organization.nameFr.toLocaleLowerCase();
      const nameOrgB = b.organization.nameFr.toLocaleLowerCase();

      return compareABstringWithUserIds(a.userId, nameOrgA, b.userId, nameOrgB);
    }
  } else if (a.reportingCompany && !b.reportingCompany) {
    return -1;
  } else if (!a.reportingCompany && b.reportingCompany) {
    return 1;
  } else if (a.reportingCompany && b.reportingCompany) {
    if (i18n.language.startsWith('en')) {
      const nameCompAsubtring = a.reportingCompany.nameEn.toLocaleLowerCase();
      const nameCompBsubtring = b.reportingCompany.nameEn.toLocaleLowerCase();

      return compareABstringWithUserIds(a.userId, nameCompAsubtring, b.userId, nameCompBsubtring);
    } else {
      const nameCompAsubtring = a.reportingCompany.nameFr.toLocaleLowerCase();
      const nameCompBsubtring = b.reportingCompany.nameFr.toLocaleLowerCase();

      return compareABstringWithUserIds(a.userId, nameCompAsubtring, b.userId, nameCompBsubtring);
    }
  } else {
    return 0;
  }
};

export const sortReportHistoryResponse = (
  a: ReportHistoryResponseItem,
  b: ReportHistoryResponseItem,
  i18n: any
): number => {
  if (i18n.language.startsWith('en')) {
    const aUnit = a.unit !== null ? a.unit.nameEn : '';
    const bUnit = b.unit !== null ? b.unit.nameEn : '';
    const aComp = a.company !== null ? a.company.nameEn : '';
    const bComp = b.company !== null ? b.company.nameEn : '';
    return (
      aComp.localeCompare(bComp) ||
      aUnit.localeCompare(bUnit) ||
      a.reportRequestor.localeCompare(b.reportRequestor) ||
      (a.reportDate < b.reportDate ? 1 : a.reportDate > b.reportDate ? -1 : 0)
    );
  }
  const aUnit = a.unit !== null ? a.unit.nameFr : '';
  const bUnit = b.unit !== null ? b.unit.nameFr : '';
  const aComp = a.company !== null ? a.company.nameFr : '';
  const bComp = b.company !== null ? b.company.nameFr : '';

  return (
    aComp.localeCompare(bComp) ||
    aUnit.localeCompare(bUnit) ||
    a.reportRequestor.localeCompare(b.reportRequestor) ||
    (a.reportDate < b.reportDate ? 1 : a.reportDate > b.reportDate ? -1 : 0)
  );
};

export const sortVehicleClaimsDesc = (a: VehicleClaim, b: VehicleClaim, i18n: any): number => {
  if (a.dateOfLoss === b.dateOfLoss) {
    if (!a.insurer && !b.insurer) {
      return 0;
    } else if (a.insurer && !b.insurer) {
      return -1;
    } else if (!a.insurer && b.insurer) {
      return 1;
    } else if (i18n.language.startsWith('en')) {
      const insurerNameA = a.insurer.nameEn.toLocaleLowerCase();
      const insurerNameB = b.insurer.nameEn.toLocaleLowerCase();
      return insurerNameA < insurerNameB ? -1 : 1;
    } else {
      const insurerNameA = a.insurer.nameFr.toLocaleLowerCase();
      const insurerNameB = b.insurer.nameFr.toLocaleLowerCase();
      return insurerNameA < insurerNameB ? -1 : 1;
    }
  } else {
    return a.dateOfLoss < b.dateOfLoss ? 1 : -1;
  }
};

export const sortVehiclePoliciesDesc = (a: VehiclePolicy, b: VehiclePolicy, i18n: any): number => {
  if (a.startDate === b.startDate) {
    if (i18n.language.startsWith('en')) {
      const insurerNameA = a.carrier.nameEn.toLocaleLowerCase();
      const insurerNameB = b.carrier.nameEn.toLocaleLowerCase();
      return insurerNameA < insurerNameB ? -1 : 1;
    } else {
      const insurerNameA = a.carrier.nameFr.toLocaleLowerCase();
      const insurerNameB = b.carrier.nameFr.toLocaleLowerCase();
      return insurerNameA < insurerNameB ? -1 : 1;
    }
  } else {
    return a.startDate < b.startDate ? 1 : -1;
  }
};

export const sortSponsors = (a: Sponsor, b: Sponsor, i18n: any): number => {
  if (i18n.language.startsWith('en')) {
    if (a.organization.nameEn.toLocaleLowerCase() < b.organization.nameEn.toLocaleLowerCase()) {
      return -1;
    } else if (a.organization.nameEn.toLocaleLowerCase() === b.organization.nameEn.toLocaleLowerCase()) {
      if (!!a.reportingCompany && !!b.reportingCompany) {
        if (a.reportingCompany.nameEn.toLocaleLowerCase() < b.reportingCompany.nameEn.toLocaleLowerCase()) {
          return -1;
        } else if (a.reportingCompany.nameEn.toLocaleLowerCase() === b.reportingCompany.nameEn.toLocaleLowerCase()) {
          return 0;
        } else if (a.reportingCompany.nameEn.toLocaleLowerCase() > b.reportingCompany.nameEn.toLocaleLowerCase()) {
          return 1;
        } else return 0;
      } else return 0;
    } else if (a.organization.nameEn.toLocaleLowerCase() > b.organization.nameEn.toLocaleLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  } else {
    if (a.organization.nameFr.toLocaleLowerCase() < b.organization.nameFr.toLocaleLowerCase()) {
      return -1;
    } else if (a.organization.nameFr.toLocaleLowerCase() === b.organization.nameFr.toLocaleLowerCase()) {
      if (!!a.reportingCompany && !!b.reportingCompany) {
        if (a.reportingCompany.nameFr.toLocaleLowerCase() < b.reportingCompany.nameFr.toLocaleLowerCase()) {
          return -1;
        } else if (a.reportingCompany.nameFr.toLocaleLowerCase() === b.reportingCompany.nameFr.toLocaleLowerCase()) {
          return 0;
        } else if (a.reportingCompany.nameFr.toLocaleLowerCase() > b.reportingCompany.nameFr.toLocaleLowerCase()) {
          return 1;
        } else return 0;
      } else return 0;
    } else if (a.organization.nameFr.toLocaleLowerCase() > b.organization.nameFr.toLocaleLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }
};

/**
 * Sort AppFunctions object by sortOrder (configured in BE)
 * @param a One AppFunction to compare
 * @param b Next AppFunction to compare
 * @returns -1 | 0 | 1
 */
export const sortAppFunctions = (a: AppFunction, b: AppFunction): number => {
  return a.sortOrder < b.sortOrder ? -1 : a.sortOrder > b.sortOrder ? 1 : 0;
};

/**
 * Sort AppFunctions string (so only their names) by sortOrder of the redux state of AppFunctions (configured in BE)
 * @param a String name of one AppFunction to compare
 * @param b String name of the next App Function to compare
 * @param appFunctionsState The Redux state of the AppFunctions
 * @param i18n The translation context
 * @returns -1 | 0 | 1
 */
export const sortAppFunctionsString = (
  a: string,
  b: string,
  appFunctionsState: Array<AppFunction>,
  i18n: any
): number => {
  const stringFunctionA = appFunctionsState.find((oneFunc) =>
    i18n.language.startsWith('en') ? oneFunc.nameEn === a : oneFunc.nameFr === a
  );
  const stringFunctionB = appFunctionsState.find((oneFunc) =>
    i18n.language.startsWith('en') ? oneFunc.nameEn === b : oneFunc.nameFr === b
  );
  if (stringFunctionA && stringFunctionB) {
    return stringFunctionA.sortOrder < stringFunctionB.sortOrder
      ? -1
      : stringFunctionA.sortOrder > stringFunctionB.sortOrder
      ? 1
      : 0;
  } else return 0;
};
