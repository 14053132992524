import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { unwrapResult } from '@reduxjs/toolkit';

import Loader from '../../Components/Loader';
import Message from '../../Components/Message';
import { PAGINATION_PAGESIZE } from '../../Helpers/Constants';
import { formatDateToLocalTime } from '../../Helpers/DateHelper';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import { findReportById } from '../../Slices/ReportSlice';
import theme from '../../theme';
import { Functions, ReactLocationState, Report, ReportHistoryRequest, ReportHistoryResponseItem } from '../../Types';

const useStyles = makeStyles(() => ({
  headerCell: {
    fontWeight: '700',
  },
  buttonText: {
    fontWeight: 700,
    padding: 0,
    minWidth: 0,
    margin: 0,
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  textCell: {
    fontWeight: 300,
    fontSize: '11pt',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.primary.dark,
  },
}));

interface ReportHistoryResultsProps {
  searchParams: ReportHistoryRequest;
  results: Array<ReportHistoryResponseItem>;
  count: number;
  pageNo: number;
  handleChangePage: (event: unknown, newPage: number) => void;
}

interface HeadCell {
  id: keyof ReportHistoryResponseItem;
  label: string;
  width: number;
}

const ReportHistoryResults: FC<ReportHistoryResultsProps> = ({
  searchParams,
  results,
  count,
  pageNo,
  handleChangePage,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { state } = useLocation<ReactLocationState>();
  const handleError = useErrorHandler();

  const isPending = useSelector((state: { report: { isPending: boolean } }) => state.report.isPending);

  const headCells: readonly HeadCell[] = [
    {
      id: 'organization',
      label: t('reportHistory.table.organization'),
      width: 17,
    },
    {
      id: 'company',
      label: t('reportHistory.table.company'),
      width: 16,
    },
    {
      id: 'unit',
      label: t('reportHistory.table.unit'),
      width: 8,
    },
    {
      id: 'reportRequestor',
      label: t('reportHistory.table.reportRequestor'),
      width: 12,
    },
    {
      id: 'reportDate',
      label: t('reportHistory.table.reportDate'),
      width: 18,
    },
    {
      id: 'referenceNumber',
      label: t('reportHistory.table.reference'),
      width: 8,
    },
    {
      id: 'driverLicenseNumber',
      label: t('reportHistory.table.dln'),
      width: 16,
    },
    {
      id: 'driverName',
      label: t('reportHistory.table.driverName'),
      width: 14,
    },
  ];

  const onAction = (reportId: string) => {
    history.push({
      pathname: `/reports/${reportId}`,
      state: {
        ...state,
        reportHistorySearch: searchParams,
        reportHistorySearchResults: results,
        reportHistorySearchResultsCount: count,
      } as ReactLocationState,
    });
  };

  const handleGetReport = async (event: React.KeyboardEvent | React.MouseEvent, reportId: string) => {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      // we call display report with id = 1 and return because durig test we will set report data to store directly with report id  = 1
      onAction('1');
      return;
    }
    //Do real stuff
    try {
      const resultAction = await dispatch(findReportById({ functionId: Functions.reportHistory, id: reportId }));
      const promiseReport = unwrapResult<any>(resultAction) as Report;
      onAction(promiseReport.id);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Grid item container xs={12}>
        <Grid item container xs={6} sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)', paddingLeft: '8px' }}>
          <Typography fontWeight={700} sx={{ margin: 'auto 0px' }}>
            {`${count} ${count === 1 ? t('ninetyReport.oneResultFound') : t('ninetyReport.manyResultsFound')}`}
          </Typography>
        </Grid>
        <Grid item container xs={6}>
          <TablePagination
            sx={{ width: '100%' }}
            count={count}
            rowsPerPageOptions={[PAGINATION_PAGESIZE]}
            rowsPerPage={PAGINATION_PAGESIZE}
            page={pageNo}
            onPageChange={handleChangePage}
            labelDisplayedRows={({ from, to, count }) => {
              return '' + from + '-' + to + ` ${t('of')} ` + count;
            }}
          />
        </Grid>
      </Grid>
      {count > 0 && results ? (
        <TableContainer component={'div'} data-testid="tableContainer">
          <Table
            size="small"
            stickyHeader
            aria-label="table-90days-result"
            sx={{ tableLayout: 'fixed', width: '100%' }}
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sx={{ width: `${headCell.width}%` }}
                    data-testid={`headerCell-${headCell.id}`}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
                <TableCell key="options" sx={{ width: '10%' }} align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody data-testid="tableBody">
              {results
                .map((oneResult) => ({
                  reportId: oneResult.reportId,
                  reportDate: formatDateToLocalTime(oneResult.reportDate, true),
                  ianaTimezone: oneResult.ianaTimezone,
                  referenceNumber: oneResult.referenceNumber,
                  reportRequestor: oneResult.reportRequestor,
                  unit: i18n.language.startsWith('en')
                    ? oneResult.unit?.nameEn
                      ? oneResult.unit?.nameEn
                      : ''
                    : oneResult.unit?.nameFr
                    ? oneResult.unit?.nameFr
                    : '',
                  organization: i18n.language.startsWith('en')
                    ? oneResult.organization?.nameEn
                      ? oneResult.organization?.nameEn
                      : ''
                    : oneResult.organization?.nameFr
                    ? oneResult.organization?.nameFr
                    : '',
                  company:
                    oneResult.organization && oneResult.company
                      ? i18n.language.startsWith('en')
                        ? oneResult.company?.nameEn
                          ? oneResult.company?.nameEn
                          : ''
                        : oneResult.company?.nameFr
                        ? oneResult.company?.nameFr
                        : ''
                      : i18n.language.startsWith('en')
                      ? oneResult.organization?.nameEn
                        ? oneResult.organization?.nameEn
                        : ''
                      : oneResult.organization?.nameFr
                      ? oneResult.organization?.nameFr
                      : '',
                  driverLicenseNumber: oneResult.driverLicenseNumber,
                  driverName: oneResult.driverName,
                  licenseJurisdiction: oneResult.licenseJurisdiction.toString(),
                }))
                .map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableCell}
                      sx={{ width: '17%' }}
                      data-testid={`tableCell-org-${index}`}
                    >
                      <Tooltip title={row.organization} enterTouchDelay={0}>
                        <Typography className={classes.textCell}>{row.organization}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      sx={{ width: '16%' }}
                      data-testid={`tableCell-org-${index}`}
                    >
                      <Tooltip title={row.company} enterTouchDelay={0}>
                        <Typography className={classes.textCell}>{row.company}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableCell} sx={{ width: '8%' }} data-testid={`unitCell${index}`}>
                      <Tooltip title={row.unit ?? ''} enterTouchDelay={0}>
                        <Typography className={classes.textCell}>{row.unit ?? ''}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      sx={{ width: '12%' }}
                      data-testid={`reportRequestorCell${index}`}
                    >
                      <Tooltip enterTouchDelay={0} title={row.reportRequestor ?? ''}>
                        <Typography className={classes.textCell}>{row.reportRequestor}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      sx={{ width: '18%' }}
                      data-testid={`reportDateCell${index}`}
                    >
                      <Tooltip enterTouchDelay={0} title={row.reportDate ?? ''}>
                        <Typography className={classes.textCell}>{row.reportDate}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      sx={{ width: '8%' }}
                      data-testid={`referenceNumberCell${index}`}
                      align="left"
                    >
                      <Tooltip enterTouchDelay={0} title={row.referenceNumber}>
                        <Typography className={classes.textCell}>{row.referenceNumber}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      sx={{ width: '16%' }}
                      data-testid={`driverLicenseNumberCell${index}`}
                      align="left"
                    >
                      <Tooltip enterTouchDelay={0} title={row.driverLicenseNumber}>
                        <Typography className={classes.textCell}>{row.driverLicenseNumber}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      sx={{ width: '10%' }}
                      data-testid={`driverNameCell${index}`}
                      align="left"
                    >
                      <Tooltip enterTouchDelay={0} title={row.driverName}>
                        <Typography className={classes.textCell}>{row.driverName}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell} sx={{ width: '10%' }}>
                      <Button
                        className={classes.buttonText}
                        variant="text"
                        color="primary"
                        id={`btnViewReport${index}`}
                        onClick={(e) => handleGetReport(e, row.reportId)}
                        endIcon={<ArrowRightIcon />}
                        data-testid={`viewBtn${index}`}
                      >
                        {t('userAdmin.userAdmin.view')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TablePagination
              count={count}
              rowsPerPageOptions={[PAGINATION_PAGESIZE]}
              rowsPerPage={PAGINATION_PAGESIZE}
              page={pageNo}
              onPageChange={handleChangePage}
              labelDisplayedRows={({ from, to, count }) => {
                return '' + from + '-' + to + ` ${t('of')} ` + count;
              }}
            />
          </Table>
        </TableContainer>
      ) : (
        <Grid
          item
          container
          xs={12}
          padding={2}
          justifyContent="center"
          marginTop={theme.spacing(2)}
          data-testid="headerNoResults"
        >
          <Message message={t('reportHistory.noResults')} severity="info" />
        </Grid>
      )}
      {isPending && <Loader open={true} />}
    </>
  );
};
export default ReportHistoryResults;
