import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import { DriverReportOptions, NotificationRecipientOptions, PermittedDomains, Units } from '../../../axios';
import Loader from '../../../Components/Loader';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { addNotification } from '../../../Slices/NotificationSlice';
import theme from '../../../theme';
import { CompanyProfileItemType, CompanyProfileOption } from '../../../Types/CompanyProfileItemType';

export interface ConfirmDialogProps {
  itemToDelete: CompanyProfileOption;
  itemToDeleteType?: CompanyProfileItemType;
  selectedOrgId?: number;
  selectedRptCompanyId?: number | null;
  contentText: string;
  isDelete: boolean;
  isDisabled?: boolean;
  onAction: (isConfirmed: boolean) => void;
  onDeleted: (id: number, itemType: CompanyProfileItemType) => void;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  itemToDelete,
  itemToDeleteType,
  contentText,
  isDelete,
  isDisabled = false,
  onAction,
  onDeleted,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleError = useErrorHandler();

  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const enterKeyRef = useRef<HTMLButtonElement>(null);

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      enterKeyRef.current?.click();
    }
  };

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleConfirm = async () => {
    setOpen(false);
    if (isDelete) {
      setIsLoading(true);
      if (itemToDelete && itemToDeleteType === CompanyProfileItemType.Unit) {
        await Units.disable(itemToDelete.id)
          .then((unit) => {
            dispatch(addNotification(200, 'success', t('successMessage.deleted', { resource: unit.nameEn })));
            onDeleted(unit.id, itemToDeleteType);
            setIsLoading(false);
          })
          .catch((error) => {
            handleError(error);
            setIsLoading(false);
          });
      } else if (itemToDelete && itemToDeleteType === CompanyProfileItemType.Dro) {
        await DriverReportOptions.disable(itemToDelete.id)
          .then((dro) => {
            onDeleted(dro.id, itemToDeleteType);
            setIsLoading(false);
            dispatch(addNotification(200, 'error', t('companyAdmin.droDeleted', { years: dro.yearsOfData })));
          })
          .catch((error) => {
            handleError(error);
            setIsLoading(false);
          });
      } else if (itemToDelete && itemToDeleteType === CompanyProfileItemType.Nro) {
        await NotificationRecipientOptions.disable(itemToDelete.id)
          .then((nro) => {
            dispatch(addNotification(200, 'error', t('companyAdmin.nroDeleted', { email: nro.email })));
            onDeleted(nro.id, itemToDeleteType);
            setIsLoading(false);
          })
          .catch((error) => {
            handleError(error);
            setIsLoading(false);
          });
      } else if (itemToDelete && itemToDeleteType === CompanyProfileItemType.Domain) {
        await PermittedDomains.disable(itemToDelete.id)
          .then((domain) => {
            dispatch(addNotification(200, 'error', t('companyAdmin.domainDeleted', { domain: domain.domain })));
            onDeleted(domain.id, itemToDeleteType);
            setIsLoading(false);
          })
          .catch((error) => {
            handleError(error);
            setIsLoading(false);
          });
      }
    }

    onAction(true);
  };

  const handleClose = () => {
    setOpen(false);
    onAction(false);
  };

  const getItemToDeleteText = () => {
    if (itemToDeleteType === CompanyProfileItemType.Unit) {
      return `${t('userAdmin.companyProf.dialogTitleUnit')}`;
    } else if (itemToDeleteType === CompanyProfileItemType.Dro) {
      return t('userAdmin.companyProf.dialogTitleDro');
    } else if (itemToDeleteType === CompanyProfileItemType.Nro) {
      return `${t('userAdmin.companyProf.dialogTitleNro')}`;
    } else if (itemToDeleteType === CompanyProfileItemType.Domain) {
      return `${t('userAdmin.companyProf.dialogTitleDomain')}`;
    } else {
      return '';
    }
  };
  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose();
          }
        }}
        data-testid="confirmDialogComponent"
      >
        {isDelete ? (
          <Typography
            variant="h5"
            sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingTop: theme.spacing(4) }}
          >{`${t('userAdmin.companyProf.delete')} ${getItemToDeleteText()}`}</Typography>
        ) : (
          <Typography
            variant="h5"
            sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingTop: theme.spacing(4) }}
          >{`${
            isDisabled ? t('userProfile.dialogEnableTitle') : t('userProfile.dialogDisableTitle')
          } ${getItemToDeleteText()}`}</Typography>
        )}

        <DialogContent>
          <Box padding={1}>
            <Typography>{contentText}</Typography>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingBottom: theme.spacing(4) }}
        >
          <Grid container spacing={1} justifyContent={'flex-end'}>
            <Grid item xs={4}>
              <Button
                fullWidth
                data-testid="cancel"
                variant="contained"
                color="secondary"
                onClick={() => handleClose()}
              >
                {t('dialog.cancel')}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                data-testid={isDelete ? 'acceptDelete' : 'accept'}
                variant="contained"
                color="primary"
                ref={enterKeyRef}
                onClick={() => handleConfirm()}
              >
                {isDelete ? t('dialog.delete') : isDisabled ? t('dialog.enable') : t('dialog.disable')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Loader open={isLoading} />
    </>
  );
};

export default ConfirmDialog;
