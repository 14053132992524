import { requests } from '../axios';
import { ClientFactory } from '../Types';
import {
  Client,
  ClientSearchRequest,
  ClientType,
  ClientTypeFactory,
  OrganizationSponsorFactory,
  ReportingCompanySponsorFactory,
  Sponsor,
  SponsorFactory,
} from '../Types/ClientSponsor';

/*
  GET functions
 */

export const listDashClients = async (params: ClientSearchRequest): Promise<Array<Client>> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Array<Client>>((resolve) => {
      resolve([
        ClientFactory({
          id: 1,
          clientType: ClientTypeFactory({
            id: 1,
            nameEn: 'IBC',
            nameFr: 'BAC',
            canHaveSponsors: false,
            isModifiableOrDeletable: false,
          }),
          hasUser: true,
          hasEmployedUser: true,
          sponsors: [],
          nameEn: 'Insurance Bureau of Canada',
          nameFr: 'Bureau Assurance Canada',
          isActive: true,
          isSponsor: false,
          hasDriverReport: false,
        }),
        ClientFactory({
          id: 2,
          clientType: ClientTypeFactory({
            id: 2,
            nameEn: 'Organization',
            nameFr: 'Organisation',
            canHaveSponsors: false,
            isModifiableOrDeletable: false,
          }),
          hasUser: true,
          hasEmployedUser: true,
          sponsors: [],
          nameEn: 'Albert Insurance Organization',
          nameFr: 'Organisation Assurance Alberta',
          isActive: true,
          isSponsor: false,
          hasDriverReport: false,
        }),
        ClientFactory({
          id: 5,
          clientType: ClientTypeFactory({
            id: 3,
            nameEn: 'Company',
            nameFr: 'Entreprise',
            canHaveSponsors: false,
            isModifiableOrDeletable: false,
          }),
          hasUser: false,
          hasEmployedUser: true,
          sponsors: [],
          nameEn: 'Alberta Insurance Company',
          nameFr: 'Companie assurance de Alberta',
          isActive: true,
          isSponsor: false,
          hasDriverReport: false,
        }),
        ClientFactory({
          id: 3,
          clientType: ClientTypeFactory({
            id: 4,
            nameEn: 'Third Party',
            nameFr: 'Tiers partie',
            canHaveSponsors: false,
            isModifiableOrDeletable: true,
          }),
          hasUser: false,
          hasEmployedUser: true,
          sponsors: [],
          nameEn: 'Third Party 1',
          nameFr: 'Tierce partie 1',
          isActive: true,
          isSponsor: false,
          hasDriverReport: false,
        }),
        ClientFactory({
          id: 6,
          clientType: ClientTypeFactory({
            id: 5,
            nameEn: 'Other',
            nameFr: 'Autre',
            canHaveSponsors: false,
            isModifiableOrDeletable: true,
          }),
          hasUser: true,
          hasEmployedUser: true,
          sponsors: [],
          nameEn: 'Other client',
          nameFr: 'Autre client',
          isActive: true,
          isSponsor: false,
          hasDriverReport: false,
        }),
        ClientFactory({
          id: 4,
          clientType: ClientTypeFactory({
            id: 6,
            nameEn: 'Broker',
            nameFr: 'Courtier',
            canHaveSponsors: true,
            isModifiableOrDeletable: true,
          }),
          hasUser: false,
          hasEmployedUser: true,
          sponsors: [],
          nameEn: 'ABC, Broker of Quebec',
          nameFr: 'Courtier du Québec ABC',
          isActive: true,
          isSponsor: false,
          hasDriverReport: false,
        }),
        ClientFactory({
          id: 7,
          clientType: ClientTypeFactory({
            id: 6,
            nameEn: 'Broker',
            nameFr: 'Courtier',
            canHaveSponsors: true,
            isModifiableOrDeletable: true,
          }),
          hasUser: false,
          hasEmployedUser: true,
          sponsors: [
            SponsorFactory({
              id: 1,
              clientId: 7,
              organization: OrganizationSponsorFactory({
                id: 1,
                nameEn: 'Sponsor Org 1 En',
                nameFr: 'Org Sponsor 1 Fr',
              }),
              reportingCompany: ReportingCompanySponsorFactory({
                id: 1,
                nameEn: 'Sponsor Rep 1 En',
                nameFr: 'Rep Sponsor 1 Fr',
              }),
              hasUser: false,
            }),
          ],
          nameEn: '123, Broker of Manitoba',
          nameFr: 'Courtier du Manitoba 123',
          isActive: true,
          isSponsor: false,
          hasDriverReport: false,
        }),
        ClientFactory({
          id: 8,
          clientType: ClientTypeFactory({
            id: 6,
            nameEn: 'Broker',
            nameFr: 'Courtier',
            canHaveSponsors: true,
            isModifiableOrDeletable: true,
          }),
          hasUser: false,
          hasEmployedUser: true,
          sponsors: [
            SponsorFactory({
              id: 1,
              clientId: 8,
              organization: OrganizationSponsorFactory({
                id: 1,
                nameEn: 'Sponsor Org 1 En',
                nameFr: 'Org Sponsor 1 Fr',
              }),
              reportingCompany: ReportingCompanySponsorFactory({
                id: 1,
                nameEn: 'Sponsor Rep 1 En',
                nameFr: 'Rep Sponsor 1 Fr',
              }),
              hasUser: false,
            }),
            SponsorFactory({
              id: 2,
              clientId: 8,
              organization: OrganizationSponsorFactory({
                id: 1,
                nameEn: 'Sponsor Org 1 En',
                nameFr: 'Org Sponsor 1 Fr',
              }),
              reportingCompany: ReportingCompanySponsorFactory({
                id: 2,
                nameEn: 'Sponsor Rep 2 En',
                nameFr: 'Rep Sponsor 2 Fr',
              }),
              hasUser: false,
            }),
            SponsorFactory({
              id: 3,
              clientId: 8,
              organization: OrganizationSponsorFactory({
                id: 3,
                nameEn: 'Sponsor Org 3 En',
                nameFr: 'Org Sponsor 3 Fr',
              }),
              reportingCompany: ReportingCompanySponsorFactory({
                id: 3,
                nameEn: 'Sponsor Rep 3 En',
                nameFr: 'Rep Sponsor 3 Fr',
              }),
              hasUser: false,
            }),
          ],
          nameEn: 'Testarossa, Broker of New Columbia',
          nameFr: 'Courtier de Nouvelle Colombie Testarossa',
          isActive: true,
          isSponsor: false,
          hasDriverReport: false,
        }),
      ]);
    });
  } else {
    return requests.get('/Client/List', params);
  }
};

export const getClientDetails = async (id: string, clientType: string): Promise<Client> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Client>((resolve) => {
      resolve(
        id === '5'
          ? ClientFactory({
              id: 5,
              clientType: ClientTypeFactory({
                id: 3,
                nameEn: 'Company',
                nameFr: 'Entreprise',
                canHaveSponsors: false,
                isModifiableOrDeletable: false,
              }),
              hasUser: false,
              hasEmployedUser: true,
              sponsors: [],
              nameEn: 'Alberta Insurance Company',
              nameFr: 'Companie assurance de Alberta',
              isActive: true,
              isSponsor: false,
              hasDriverReport: false,
            })
          : id === '4'
          ? ClientFactory({
              id: 4,
              clientType: ClientTypeFactory({
                id: 6,
                nameEn: 'Broker',
                nameFr: 'Courtier',
                canHaveSponsors: true,
                isModifiableOrDeletable: true,
              }),
              hasUser: false,
              hasEmployedUser: true,
              sponsors: [],
              nameEn: 'ABC, Broker of Quebec',
              nameFr: 'Courtier du Québec ABC',
              isActive: true,
              isSponsor: false,
              hasDriverReport: false,
            })
          : id === '3'
          ? ClientFactory({
              id: 3,
              clientType: ClientTypeFactory({
                id: 4,
                nameEn: 'Third Party',
                nameFr: 'Tiers partie',
                canHaveSponsors: false,
                isModifiableOrDeletable: true,
              }),
              hasUser: false,
              hasEmployedUser: true,
              sponsors: [],
              nameEn: 'Third Party 1',
              nameFr: 'Tierce partie 1',
              isActive: true,
              isSponsor: false,
              hasDriverReport: false,
            })
          : id === '1'
          ? ClientFactory({
              id: 1,
              clientType: ClientTypeFactory({
                id: 1,
                nameEn: 'IBC',
                nameFr: 'BAC',
                canHaveSponsors: false,
                isModifiableOrDeletable: false,
              }),
              hasUser: true,
              hasEmployedUser: true,
              sponsors: [],
              nameEn: 'Insurance Bureau of Canada',
              nameFr: 'Bureau Assurance Canada',
              isActive: true,
              isSponsor: false,
              hasDriverReport: false,
            })
          : id === '7'
          ? ClientFactory({
              id: 7,
              clientType: ClientTypeFactory({
                id: 6,
                nameEn: 'Broker',
                nameFr: 'Courtier',
                canHaveSponsors: true,
                isModifiableOrDeletable: true,
              }),
              hasUser: false,
              hasEmployedUser: true,
              sponsors: [
                SponsorFactory({
                  id: 1,
                  clientId: 7,
                  organization: OrganizationSponsorFactory({
                    id: 1,
                    nameEn: 'Sponsor Org 1 En',
                    nameFr: 'Org Sponsor 1 Fr',
                  }),
                  reportingCompany: ReportingCompanySponsorFactory({
                    id: 1,
                    nameEn: 'Sponsor Rep 1 En',
                    nameFr: 'Rep Sponsor 1 Fr',
                  }),
                  hasUser: false,
                }),
              ],
              nameEn: '123, Broker of Manitoba',
              nameFr: 'Courtier du Manitoba 123',
              isActive: true,
              isSponsor: false,
              hasDriverReport: false,
            })
          : id === '8'
          ? ClientFactory({
              id: 8,
              clientType: ClientTypeFactory({
                id: 6,
                nameEn: 'Broker',
                nameFr: 'Courtier',
                canHaveSponsors: true,
                isModifiableOrDeletable: true,
              }),
              hasUser: false,
              hasEmployedUser: true,
              sponsors: [
                SponsorFactory({
                  id: 1,
                  clientId: 8,
                  organization: OrganizationSponsorFactory({
                    id: 1,
                    nameEn: 'Sponsor Org 1 En',
                    nameFr: 'Org Sponsor 1 Fr',
                  }),
                  reportingCompany: ReportingCompanySponsorFactory({
                    id: 1,
                    nameEn: 'Sponsor Rep 1 En',
                    nameFr: 'Rep Sponsor 1 Fr',
                  }),
                  hasUser: false,
                }),
                SponsorFactory({
                  id: 2,
                  clientId: 8,
                  organization: OrganizationSponsorFactory({
                    id: 1,
                    nameEn: 'Sponsor Org 1 En',
                    nameFr: 'Org Sponsor 1 Fr',
                  }),
                  reportingCompany: ReportingCompanySponsorFactory({
                    id: 2,
                    nameEn: 'Sponsor Rep 2 En',
                    nameFr: 'Rep Sponsor 2 Fr',
                  }),
                  hasUser: false,
                }),
                SponsorFactory({
                  id: 3,
                  clientId: 8,
                  organization: OrganizationSponsorFactory({
                    id: 3,
                    nameEn: 'Sponsor Org 3 En',
                    nameFr: 'Org Sponsor 3 Fr',
                  }),
                  reportingCompany: ReportingCompanySponsorFactory({
                    id: 3,
                    nameEn: 'Sponsor Rep 3 En',
                    nameFr: 'Rep Sponsor 3 Fr',
                  }),
                  hasUser: false,
                }),
              ],
              nameEn: 'Testarossa, Broker of New Columbia',
              nameFr: 'Courtier de Nouvelle Colombie Testarossa',
              isActive: true,
              isSponsor: false,
              hasDriverReport: false,
            })
          : ClientFactory({
              id: 6,
              clientType: ClientTypeFactory({
                id: 5,
                nameEn: 'Other',
                nameFr: 'Autre',
                canHaveSponsors: false,
                isModifiableOrDeletable: true,
              }),
              hasUser: false,
              hasEmployedUser: true,
              sponsors: [],
              nameEn: 'Other client',
              nameFr: 'Autre client',
              isActive: true,
              isSponsor: false,
              hasDriverReport: false,
            })
      );
    });
  } else {
    return requests.get(`/Client/${id}/ClientType/${clientType}`, {});
  }
};

export const getClientTypes = async (): Promise<Array<ClientType>> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Array<ClientType>>((resolve) => {
      resolve([
        ClientTypeFactory({
          id: 1,
          nameEn: 'IBC',
          nameFr: 'BAC',
          canHaveSponsors: false,
          isModifiableOrDeletable: false,
        }),
        ClientTypeFactory({
          id: 2,
          nameEn: 'Organization',
          nameFr: 'Organisation',
          canHaveSponsors: false,
          isModifiableOrDeletable: false,
        }),
        ClientTypeFactory({
          id: 3,
          nameEn: 'Company',
          nameFr: 'Entreprise',
          canHaveSponsors: false,
          isModifiableOrDeletable: false,
        }),
        ClientTypeFactory({
          id: 4,
          nameEn: 'Third Party',
          nameFr: 'Tiers partie',
          canHaveSponsors: false,
          isModifiableOrDeletable: true,
        }),
        ClientTypeFactory({
          id: 5,
          nameEn: 'Others',
          nameFr: 'Autres',
          canHaveSponsors: false,
          isModifiableOrDeletable: true,
        }),
        ClientTypeFactory({
          id: 6,
          nameEn: 'Broker',
          nameFr: 'Courtier',
          canHaveSponsors: true,
          isModifiableOrDeletable: true,
        }),
      ]);
    });
  } else {
    return requests.get('/Client/ClientTypes', {});
  }
};

export const getSponsor = async (id: number): Promise<Sponsor> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Sponsor>((resolve) => {
      resolve(
        SponsorFactory({
          id: 1,
          clientId: 1,
          organization: OrganizationSponsorFactory({ id: 1, nameEn: 'Orga Spon en', nameFr: 'Orga Spon fr' }),
          reportingCompany: ReportingCompanySponsorFactory({
            id: 2,
            nameEn: 'Rep Spon en',
            nameFr: 'Rep Spon fr',
          }),
          hasUser: false,
        })
      );
    });
  } else {
    return requests.get(`/Client/Sponsor/${id}`, {});
  }
};

export const getSponsors = async (thirdPartyId: number): Promise<Sponsor[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Sponsor[]>((resolve) => {
      resolve([
        SponsorFactory({
          id: 1,
          clientId: 8,
          organization: OrganizationSponsorFactory({
            id: 1,
            nameEn: 'Sponsor Org 1 En',
            nameFr: 'Org Sponsor 1 Fr',
          }),
          reportingCompany: ReportingCompanySponsorFactory({
            id: 1,
            nameEn: 'Sponsor Rep 1 En',
            nameFr: 'Rep Sponsor 1 Fr',
          }),
          hasUser: false,
        }),
        SponsorFactory({
          id: 2,
          clientId: 8,
          organization: OrganizationSponsorFactory({
            id: 1,
            nameEn: 'Sponsor Org 1 En',
            nameFr: 'Org Sponsor 1 Fr',
          }),
          reportingCompany: ReportingCompanySponsorFactory({
            id: 2,
            nameEn: 'Sponsor Rep 2 En',
            nameFr: 'Rep Sponsor 2 Fr',
          }),
          hasUser: false,
        }),
        SponsorFactory({
          id: 3,
          clientId: 8,
          organization: OrganizationSponsorFactory({
            id: 3,
            nameEn: 'Sponsor Org 3 En',
            nameFr: 'Org Sponsor 3 Fr',
          }),
          reportingCompany: ReportingCompanySponsorFactory({
            id: 3,
            nameEn: 'Sponsor Rep 3 En',
            nameFr: 'Rep Sponsor 3 Fr',
          }),
          hasUser: false,
        }),
      ]);
    });
  } else {
    return requests.get(`/Client/Sponsors/${thirdPartyId}`, {});
  }
};

/*
  POST functions
 */

export const createClient = async (client: Client): Promise<Client> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Client>((resolve) => {
      resolve(
        ClientFactory({
          id: client.id,
          clientType: client.clientType,
          hasUser: client.hasUser,
          hasEmployedUser: client.hasEmployedUser,
          sponsors: client.sponsors,
          nameEn: client.nameEn,
          nameFr: client.nameFr,
          isActive: client.isActive,
          isSponsor: client.isSponsor,
          hasDriverReport: client.hasDriverReport,
        })
      );
    });
  } else {
    return requests.post('/Client/Create', client);
  }
};

export const createSponsor = async (sponsor: Sponsor): Promise<Sponsor> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Sponsor>((resolve) => {
      resolve(
        SponsorFactory({
          id: 1,
          clientId: 1,
          organization: OrganizationSponsorFactory({ id: 1, nameEn: 'Orga Spon en', nameFr: 'Orga Spon fr' }),
          reportingCompany: ReportingCompanySponsorFactory({
            id: 2,
            nameEn: 'Rep Spon en',
            nameFr: 'Rep Spon fr',
          }),
          hasUser: false,
        })
      );
    });
  } else {
    return requests.post('/Client/Sponsors/Create', sponsor);
  }
};

/*
  PUT functions
 */

export const updateClient = async (client: Client): Promise<Client> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Client>((resolve) => {
      resolve(
        ClientFactory({
          id: client.id,
          clientType: client.clientType,
          hasUser: client.hasUser,
          hasEmployedUser: client.hasEmployedUser,
          sponsors: client.sponsors,
          nameEn: client.nameEn,
          nameFr: client.nameFr,
          isActive: client.isActive,
          isSponsor: client.isSponsor,
          hasDriverReport: client.hasDriverReport,
        })
      );
    });
  } else {
    return requests.put(`/Client/${client.id}`, client);
  }
};

/*
  DELETE functions
 */

export const deleteClient = async (id: number): Promise<Client> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Client>((resolve) => {
      resolve(
        ClientFactory({
          id: id,
          clientType: ClientTypeFactory({
            id: 6,
            nameEn: 'Broker',
            nameFr: 'Courtier',
            canHaveSponsors: true,
            isModifiableOrDeletable: true,
          }),
          hasUser: false,
          hasEmployedUser: false,
          sponsors: [],
          nameEn: 'Alberta Insurance Company',
          nameFr: 'Companie assurance de Alberta',
          isActive: true,
          isSponsor: false,
          hasDriverReport: false,
        })
      );
    });
  } else {
    return requests.delete(`/Client/${id}`);
  }
};

export const deleteSponsor = async (id: number): Promise<Sponsor> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Sponsor>((resolve) => {
      resolve(
        SponsorFactory({
          id: 1,
          clientId: 1,
          organization: OrganizationSponsorFactory({ id: 1, nameEn: 'Orga Spon en', nameFr: 'Orga Spon fr' }),
          reportingCompany: ReportingCompanySponsorFactory({
            id: 2,
            nameEn: 'Rep Spon en',
            nameFr: 'Rep Spon fr',
          }),
          hasUser: false,
        })
      );
    });
  } else {
    return requests.delete(`/Client/Sponsors/${id}`);
  }
};
