import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { Grid } from '@mui/material';

import { ReportHistory } from '../../axios';
import Loader from '../../Components/Loader';
import { formatDateToLocalTime } from '../../Helpers/DateHelper';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import {
  ReactLocationState,
  ReportHistoryRequest,
  ReportHistoryResponseItem,
  ReportHistoryResponseItemCsv,
} from '../../Types';
import { PaginationQuery } from '../../Types/PaginationQuery';
import ReportHistorySearch from './ReportHistorySearch';

const ReportsHistory: FC = () => {
  const { state, pathname } = useLocation<ReactLocationState>();
  const history = useHistory();
  const { i18n } = useTranslation();
  const handleError = useErrorHandler();

  const csvLink = useRef<any>(null);

  const [results, setResults] = useState<Array<ReportHistoryResponseItem> | null>(
    state && state.reportHistorySearchResults ? state.reportHistorySearchResults : null
  );
  const [countResults, setCountResults] = useState<number>(
    state && state.reportHistorySearchResultsCount ? state.reportHistorySearchResultsCount : 0
  );
  const [isPending, setIsPending] = useState<boolean>(false);

  const [fileName, setFilename] = useState<string | undefined>();
  const [resultsCsv, setResultsCsv] = useState<Array<ReportHistoryResponseItemCsv> | null>(null);

  // Reseting the state of the location object at render
  useEffect(() => {
    history.replace(pathname, { from: pathname });
  }, [history, pathname]);

  const onSearch = (searchParams: ReportHistoryRequest, paginationQuery?: PaginationQuery) => {
    setIsPending(true);

    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      setResults([]);
      setCountResults(0);
      setIsPending(false);
      return;
    }

    ReportHistory.search(searchParams, paginationQuery)
      .then((promise) => {
        const newResults = promise.data as Array<ReportHistoryResponseItem>;
        setResults(newResults);
        setCountResults(promise.count);
        setIsPending(false);
      })
      .catch((error) => {
        handleError(error);
        setIsPending(false);
      });
  };

  const onExportCSV = (searchParams: ReportHistoryRequest, csvFilename: string) => {
    setIsPending(true);
    ReportHistory.search(searchParams)
      .then((promise) => {
        const newResults = promise.data as Array<ReportHistoryResponseItem>;
        setResultsCsv(
          newResults.map((oneResult) => ({
            reportId: oneResult.reportId,
            reportDate: formatDateToLocalTime(oneResult.reportDate, true),
            ianaTimezone: oneResult.ianaTimezone,
            referenceNumber: oneResult.referenceNumber,
            reportRequestor: oneResult.reportRequestor,
            unit: i18n.language.startsWith('en')
              ? oneResult.unit?.nameEn
                ? oneResult.unit?.nameEn
                : ''
              : oneResult.unit?.nameFr
              ? oneResult.unit?.nameFr
              : '',
            organization: i18n.language.startsWith('en')
              ? oneResult.organization?.nameEn
                ? oneResult.organization?.nameEn
                : ''
              : oneResult.organization?.nameFr
              ? oneResult.organization?.nameFr
              : '',
            company:
              oneResult.organization && oneResult.company
                ? i18n.language.startsWith('en')
                  ? oneResult.company?.nameEn
                    ? oneResult.company?.nameEn
                    : ''
                  : oneResult.company?.nameFr
                  ? oneResult.company?.nameFr
                  : ''
                : i18n.language.startsWith('en')
                ? oneResult.organization?.nameEn
                  ? oneResult.organization?.nameEn
                  : ''
                : oneResult.organization?.nameFr
                ? oneResult.organization?.nameFr
                : '',
            driverLicenseNumber: oneResult.driverLicenseNumber,
            driverName: oneResult.driverName,
            licenseJurisdiction: oneResult.licenseJurisdiction.toString(),
          }))
        );
        setFilename(csvFilename);
        setIsPending(false);
        if (csvLink.current) csvLink.current.link.click(); // call csvLink
      })
      .catch((error) => {
        handleError(error);
        setIsPending(false);
      });
  };

  return (
    <>
      <Grid container spacing={4} direction="column" paddingBottom={13}>
        <Grid item container>
          <ReportHistorySearch
            results={results}
            resultsCsv={resultsCsv}
            setResultsCsv={setResultsCsv}
            countResults={countResults}
            setResults={setResults}
            setCountResults={setCountResults}
            onSearch={onSearch}
            onExportCSV={onExportCSV}
            fileName={fileName}
            csvLink={csvLink}
          />
        </Grid>
      </Grid>
      <Loader open={isPending} />
    </>
  );
};
export default ReportsHistory;
