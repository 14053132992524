/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, FormControlLabel, Grid, IconButton, Switch, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DriverReportOptions, OrganizationCompanyOptions } from '../../../axios';
import Loader from '../../../Components/Loader';
import { sortUnits } from '../../../Helpers/SortHelpers';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import useIbcOrThirdPartySelected from '../../../Hooks/UseIbcOrThirdPartySelected';
import { addNotification } from '../../../Slices/NotificationSlice';
import { getConnectedUser } from '../../../Slices/UserSlice';
import theme from '../../../theme';
import { DriverReportOption, Functions, ModeEnum, Organization, ReportingCompany, Unit } from '../../../Types';
import {
  CompanyProfileItem,
  CompanyProfileItemType,
  CompanyProfileOption,
} from '../../../Types/CompanyProfileItemType';
import NotificationRecipientOption from '../../../Types/NotificationRecipientOption';
import OrganizationCompany from '../../../Types/OrganizationCompany';
import PermittedDomainOption from '../../../Types/PermittedDomain';
import { AddDialogProps, AddRenameDialogProps } from '../Dialogs/AddDialogProps';
import AddDomainDialog from '../Dialogs/AddDomainDialog';
import AddEditDriverRptOptionDialog, {
  AddEditDriverRptOptionDialogProps,
} from '../Dialogs/AddEditDriverRptOptionDialog';
import AddRecipientDialog from '../Dialogs/AddRecipientDialog';
import AddRenameUnitDialog from '../Dialogs/AddRenameUnitDialog';
import ConfirmDialog, { ConfirmDialogProps } from '../Dialogs/ConfirmDialog';

const useStyles = makeStyles(() => ({
  tableHeader: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.white.main,
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(1),
  },
  tableRow: {
    backgroundColor: theme.palette.secondary.light,
    borderBottom: `1px solid #e0e0e0`,
    marginBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  buttonText: {
    fontWeight: 700,
  },
}));

interface CompanyProfileResultsProps {
  selectedOrg: Organization;
  selectedRptCompany: ReportingCompany | null;
  units: Array<Unit>;
  driverRepOpt: Array<DriverReportOption>;
  permittedDomains: Array<PermittedDomainOption>;
  recipients: Array<NotificationRecipientOption>;
}

const CompanyProfileResults: FC<CompanyProfileResultsProps> = ({
  selectedOrg,
  selectedRptCompany,
  units,
  driverRepOpt,
  permittedDomains,
  recipients,
}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleError = useErrorHandler();

  const connectedUser = useSelector((state: { user: any }) => getConnectedUser(state));

  const isIbcOrThirdPartySelected = useIbcOrThirdPartySelected(selectedOrg.id);

  const [addRenameUnitDialogOpen, setAddRenameUnitDialogOpen] = useState<boolean>(false);
  const [addRenameUnitDialogProps, setAddRenameUnitDialogProps] = useState<AddRenameDialogProps<Unit> | undefined>(
    undefined
  );
  const [addRecipientDialogOpen, setAddRecipientDialogOpen] = useState<boolean>(false);
  const [addRecipientDialogProps, setAddRecipientDialogProps] = useState<
    AddDialogProps<NotificationRecipientOption> | undefined
  >(undefined);
  const [addDomainDialogOpen, setAddDomainDialogOpen] = useState<boolean>(false);
  const [addDomainDialogProps, setAddDomainDialogProps] = useState<AddDialogProps<PermittedDomainOption> | undefined>(
    undefined
  );
  const [driverReportAddDialogOpen, setDriverReportAddDialogOpen] = useState<boolean>(false);
  const [driverReportAddDialogProps, setDriverReportAddDialogProps] = useState<
    AddEditDriverRptOptionDialogProps<DriverReportOption> | undefined
  >(undefined);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | undefined>(undefined);
  const [listUnits, setListUnits] = useState<Unit[]>(units);
  const [listDro, setListDro] = useState<DriverReportOption[]>(driverRepOpt);
  const [listNro, setListNro] = useState<NotificationRecipientOption[]>(recipients);
  const [listDomains, setListDomains] = useState<PermittedDomainOption[]>(permittedDomains);
  const [isIbcCredentials, setIsIbcCredentials] = useState<boolean>(false);
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);

  const createOrganizationCompanyOptions = useCallback(async () => {
    const params = {
      isIbcCredentials: false,
      organizationId: selectedOrg.id,
      reportingCompanyId: selectedRptCompany?.id,
    };
    await OrganizationCompanyOptions.create(Functions.companyAdministration, params)
      .then((response: OrganizationCompany) => {
        setIsIbcCredentials(response.isIbcCredentials);
        setIsLoadingResults(false);
      })
      .catch((error) => {
        handleError(error);
        setIsLoadingResults(false);
      });
  }, [handleError, selectedOrg.id, selectedRptCompany?.id]);

  const updateOrganizationCompanyOptions = async (newValue: boolean) => {
    const params = {
      isIbcCredentials: newValue,
      organizationId: selectedOrg.id,
      reportingCompanyId: selectedRptCompany?.id,
    };
    await OrganizationCompanyOptions.update(Functions.companyAdministration, params)
      .then((response: OrganizationCompany) => {
        dispatch(addNotification(200, 'success', t('successMessage.savedChanges')));
        setIsIbcCredentials(response.isIbcCredentials);
        setIsLoadingResults(false);
      })
      .catch((error) => {
        handleError(error);
        setIsLoadingResults(false);
      });
  };

  useEffect(() => {
    const getOrganizationCompanyOptions = async () => {
      const params = {
        organizationId: selectedOrg.id,
        reportingCompanyId: selectedRptCompany?.id,
      };
      await OrganizationCompanyOptions.get(params)
        .then((response: OrganizationCompany) => {
          setIsIbcCredentials(response.isIbcCredentials);
          setIsLoadingResults(false);
        })
        .catch((error) => {
          handleError(error);
          setIsIbcCredentials(false);
          setIsLoadingResults(false);
        });
    };

    if ((isIbcOrThirdPartySelected && selectedOrg.id) || (!isIbcOrThirdPartySelected && selectedRptCompany?.id)) {
      setIsLoadingResults(true);
      getOrganizationCompanyOptions();
    }
  }, [
    createOrganizationCompanyOptions,
    dispatch,
    handleError,
    isIbcOrThirdPartySelected,
    selectedOrg.id,
    selectedRptCompany?.id,
    setIsLoadingResults,
    t,
  ]);

  const refreshDro = () => {
    setIsLoadingResults(true);
    DriverReportOptions.getDriverReportOptionsByCompanyId(
      selectedOrg.id,
      selectedRptCompany
        ? {
            reportingCompanyId: selectedRptCompany.id,
          }
        : undefined
    )
      .then((results) => {
        setListDro(results);
        setIsLoadingResults(false);
      })
      .catch((error) => {
        handleError(error);
        setIsLoadingResults(false);
      });
  };

  const handleAddUnitClick = () => {
    setAddRenameUnitDialogProps({
      mode: ModeEnum.Create,
      selectedOrgId: selectedOrg.id,
      selectedRptCompanyId: selectedRptCompany?.id,
      onCreated: handleOnUnitCreated,
      onUpdated: handleOnUnitUpdated,
      onAction: () => {
        setAddRenameUnitDialogOpen(false);
        setAddRenameUnitDialogProps(undefined);
      },
    });
    setAddRenameUnitDialogOpen(true);
  };

  const handleAddClickDriverReportOption = () => {
    setDriverReportAddDialogProps({
      mode: ModeEnum.Create,
      selectedOrg,
      selectedRptCompany,
      onAction: () => {
        setDriverReportAddDialogOpen(false);
        setDriverReportAddDialogProps(undefined);
      },
      onCreated: handleOnCreatedDriverReportOption,
      onUpdated: handleOnUpdatedDriverReportOption,
    });
    setDriverReportAddDialogOpen(true);
  };

  const handleEditClickDriverReportOption = (item: DriverReportOption) => {
    setDriverReportAddDialogProps({
      mode: ModeEnum.Update,
      item: item,
      selectedOrg,
      selectedRptCompany,
      onAction: () => {
        setDriverReportAddDialogOpen(false);
        setDriverReportAddDialogProps(undefined);
      },
      onCreated: handleOnCreatedDriverReportOption,
      onUpdated: handleOnUpdatedDriverReportOption,
    });
    setDriverReportAddDialogOpen(true);
  };

  const handleAddClickNotificationRecipientOption = () => {
    setAddRecipientDialogProps({
      mode: ModeEnum.Create,
      selectedOrgId: selectedOrg.id,
      selectedRptCompanyId: selectedRptCompany?.id,
      onCreated: handleOnRecipientCreated,
      onAction: () => {
        setAddRecipientDialogOpen(false);
        setAddRecipientDialogProps(undefined);
      },
    });
    setAddRecipientDialogOpen(true);
  };

  const handleAddClickPermittedDomainOption = () => {
    setAddDomainDialogProps({
      mode: ModeEnum.Create,
      selectedOrgId: selectedOrg.id,
      selectedRptCompanyId: selectedRptCompany?.id,
      onCreated: handleOnDomainCreated,
      onAction: () => {
        setAddDomainDialogOpen(false);
        setAddDomainDialogProps(undefined);
      },
    });
    setAddDomainDialogOpen(true);
  };

  const handleRenameUnitClick = (item: Unit) => {
    setAddRenameUnitDialogProps({
      mode: ModeEnum.Update,
      item: item,
      selectedOrgId: selectedOrg.id,
      selectedRptCompanyId: selectedRptCompany?.id,
      onCreated: handleOnUnitCreated,
      onUpdated: handleOnUnitUpdated,
      onAction: () => {
        setAddRenameUnitDialogOpen(false);
        setAddRenameUnitDialogProps(undefined);
      },
    });
    setAddRenameUnitDialogOpen(true);
  };

  const handleDeleteClick = (item: CompanyProfileItem) => {
    setConfirmDialogProps({
      contentText: getContentText(item),
      isDelete: true,
      itemToDelete: getCompanyProfileItemOption(item),
      itemToDeleteType: item.itemType,
      selectedOrgId: selectedOrg.id,
      selectedRptCompanyId: selectedRptCompany ? selectedRptCompany.id : null,
      onDeleted: handleOnDelete,
      onAction: () => {
        setConfirmDialogOpen(false);
        setConfirmDialogProps(undefined);
      },
    });
    setConfirmDialogOpen(true);
  };

  const getContentText = (item: CompanyProfileItem): string => {
    if (item.itemType === CompanyProfileItemType.Unit) {
      return `${t('userAdmin.companyProf.contentDeleteDialog')}`;
    } else if (item.itemType === CompanyProfileItemType.Dro) {
      return t('userAdmin.companyProf.contentDeleteDialogDro', {
        noOfUsers: (item.option as DriverReportOption).noOfUsers,
      });
    } else if (item.itemType === CompanyProfileItemType.Nro) {
      return `${t('userAdmin.companyProf.contentDeleteDialog')}`;
    } else if (item.itemType === CompanyProfileItemType.Domain) {
      return `${t('userAdmin.companyProf.contentDeleteDialog')}`;
    } else {
      return '';
    }
  };

  const getCompanyProfileItemOption = (item: CompanyProfileItem): CompanyProfileOption => {
    if (item.itemType === CompanyProfileItemType.Unit) {
      return item.option as Unit;
    } else if (item.itemType === CompanyProfileItemType.Dro) {
      return item.option as DriverReportOption;
    } else if (item.itemType === CompanyProfileItemType.Nro) {
      return item.option as NotificationRecipientOption;
    } else if (item.itemType === CompanyProfileItemType.Domain) {
      return item.option as PermittedDomainOption;
    }
    return undefined;
  };

  const handleOnDelete = (id: number, itemType: CompanyProfileItemType) => {
    switch (itemType) {
      case CompanyProfileItemType.Unit: {
        setListUnits(listUnits.filter((d) => d.id !== id));
        break;
      }
      case CompanyProfileItemType.Dro: {
        setListDro(listDro.filter((dro) => dro.id !== id));
        refreshDro();
        break;
      }
      case CompanyProfileItemType.Nro: {
        setListNro(listNro.filter((nro) => nro.id !== id));
        break;
      }
      case CompanyProfileItemType.Domain: {
        setListDomains(listDomains.filter((domain) => domain.id !== id));
        break;
      }
    }
  };

  const handleOnRecipientCreated = (result: NotificationRecipientOption) => {
    const nro = result as NotificationRecipientOption;
    setListNro([...listNro, nro]);
  };

  const handleOnUnitCreated = (result: Unit) => {
    const unit = result as Unit;
    setListUnits([...listUnits, unit]);
  };

  const handleOnUnitUpdated = (result: Unit) => {
    const unit = result as Unit;
    setListUnits([...listUnits.filter((d) => d.id !== unit.id), unit]);
  };

  const handleOnDomainCreated = (result: PermittedDomainOption) => {
    const domain = result as PermittedDomainOption;
    setListDomains([...listDomains, domain]);
  };

  const handleOnCreatedDriverReportOption = (result: DriverReportOption) => {
    setListDro([...listDro, result]);
    refreshDro();
  };

  const handleOnUpdatedDriverReportOption = (result: DriverReportOption) => {
    setListDro([...listDro.filter((d) => d.id !== result.id), result]);
  };

  const handleOnChangeIsIbcCredentials = () => {
    const newValue = !isIbcCredentials;
    setIsIbcCredentials(newValue);
    setIsLoadingResults(true);
    updateOrganizationCompanyOptions(newValue);
  };

  return (
    <>
      <Grid container spacing={2} padding={2} data-testid="parentGridResults">
        <Grid item container xs={12} justifyContent="flex-start">
          <FormControlLabel
            control={
              <Switch
                checked={isIbcCredentials}
                onChange={handleOnChangeIsIbcCredentials}
                disabled={!connectedUser.isIbc}
              />
            }
            label={t('userAdmin.companyProf.ibcCredentials').toString()}
          />
        </Grid>
        <Grid item container spacing={4} xs={12}>
          {/* Department Card */}
          <Grid item container xs={12}>
            <Grid item container xs={12} alignItems="center" className={classes.tableHeader}>
              <Typography sx={{ fontWeight: 700 }}>{t('userAdmin.companyProf.units')}</Typography>
            </Grid>
            <Grid item container xs={12}>
              {listUnits
                .sort((a: Unit, b: Unit) => sortUnits(a, b))
                .map((unit, index) => (
                  <Grid key={index} item container xs={12} alignItems="center" className={classes.tableRow}>
                    <Grid item container xs={8} justifyContent="flex-start">
                      <Typography sx={{ fontWeight: '300', color: theme.palette.primary.dark }} align="left">
                        {i18n.language.startsWith('en') ? unit.nameEn : unit.nameFr}
                      </Typography>
                    </Grid>
                    <Grid item container xs={4} columnSpacing={4} justifyContent="flex-end">
                      <Grid item container xs={3}>
                        <Grid item>
                          <Button
                            fullWidth
                            className={classes.buttonText}
                            variant="text"
                            color="primary"
                            id={`btnRenameUnit${index}`}
                            onClick={() => handleRenameUnitClick(unit)}
                            endIcon={<ArrowRightIcon />}
                            data-testid={`btnRenameUnit${index}`}
                          >
                            {t('userAdmin.companyProf.rename')}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item container md={6} lg={5} xl={4}>
                        {unit.hasUsers ? (
                          <Tooltip title={t('userAdmin.companyProf.hasUserUnit').toString()}>
                            <Grid container alignItems="center">
                              <Grid
                                item
                                xs={i18n.language.startsWith('en') ? 7 : 10}
                                data-testid={`btnDeleteUnit${index}`}
                              >
                                <Typography sx={{ fontSize: '11pt', fontWeight: 700 }}>
                                  {t('userAdmin.companyProf.delete')}
                                  <IconButton size="small" sx={{ padding: '0 1px 0 3px' }}>
                                    <HelpOutlineIcon fontSize="small" sx={{ display: 'inline' }} />
                                  </IconButton>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Tooltip>
                        ) : (
                          <Grid container alignItems="center">
                            <Grid item xs={i18n.language.startsWith('en') ? 7 : 10}>
                              <Button
                                fullWidth
                                className={classes.buttonText}
                                variant="text"
                                id={`btnDeleteUnit${index}`}
                                onClick={() =>
                                  handleDeleteClick({ itemType: CompanyProfileItemType.Unit, option: unit })
                                }
                                endIcon={<ArrowRightIcon />}
                                data-testid={`btnDeleteUnit${index}`}
                              >
                                {t('userAdmin.companyProf.delete')}
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              <Grid item container xs={12} alignItems="center" className={classes.tableRow}>
                <Button
                  className={classes.buttonText}
                  variant="text"
                  id="btnAddUnit"
                  onClick={() => handleAddUnitClick()}
                  endIcon={<ArrowRightIcon />}
                  data-testid="btnAddUnit"
                >
                  {t('userAdmin.companyProf.buttonAddUnit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Report Driver Options Card */}
          <Grid item container xs={12}>
            <Grid item container xs={12} alignItems="center" className={classes.tableHeader}>
              <Typography sx={{ fontWeight: 700 }}>{t('userAdmin.companyProf.dro')}</Typography>
            </Grid>
            <Grid item container xs={12}>
              {listDro.map((dro: DriverReportOption, index: number) => (
                <Grid key={index} item container xs={12} alignItems="center" className={classes.tableRow}>
                  <Grid item container xs={8} justifyContent="flex-start">
                    <Typography sx={{ fontWeight: '300', color: theme.palette.primary.dark }} align="left">
                      {`${
                        dro.yearsOfData
                          ? `${dro.yearsOfData} ${t('userAdmin.companyProf.years')}`
                          : t('userAdmin.companyProf.maxYears')
                      } ${dro.note ? ` - ${dro.note}` : ''}`}
                    </Typography>
                  </Grid>
                  <Grid item container xs={4} columnSpacing={4} justifyContent="flex-end">
                    <Grid item container xs={3}>
                      <Grid item>
                        <Button
                          fullWidth
                          className={classes.buttonText}
                          variant="text"
                          color="primary"
                          id={`btnRenameUnit${index}`}
                          onClick={() => handleEditClickDriverReportOption(dro)}
                          endIcon={<ArrowRightIcon />}
                          data-testid={`btnEditDro${index}`}
                        >
                          {t('userAdmin.companyProf.edit')}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item container md={6} lg={5} xl={4}>
                      {dro.hasUsers ? (
                        <Tooltip key={index} title={t('userAdmin.companyProf.hasUserDro').toString()}>
                          <Grid container alignItems="center">
                            <Grid
                              item
                              xs={i18n.language.startsWith('en') ? 7 : 10}
                              data-testid={`btnDeleteDro${index}`}
                              padding={0.9}
                            >
                              <Typography sx={{ fontSize: '11pt', fontWeight: 700 }}>
                                {t('userAdmin.companyProf.delete')}
                                <IconButton size="small" sx={{ padding: '0 1px 0 3px' }}>
                                  <HelpOutlineIcon fontSize="small" sx={{ display: 'inline' }} />
                                </IconButton>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Tooltip>
                      ) : (
                        <Grid container alignItems="center">
                          <Grid item xs={i18n.language.startsWith('en') ? 7 : 10}>
                            <Button
                              fullWidth
                              variant="text"
                              className={classes.buttonText}
                              id={`btnDeleteDro${index}`}
                              onClick={() => handleDeleteClick({ itemType: CompanyProfileItemType.Dro, option: dro })}
                              endIcon={<ArrowRightIcon />}
                              data-testid={`btnDeleteDro${index}`}
                            >
                              {t('userAdmin.companyProf.delete')}
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item container xs={12} alignItems="center" className={classes.tableRow}>
                <Button
                  variant="text"
                  className={classes.buttonText}
                  id="btnAddDro"
                  onClick={() => handleAddClickDriverReportOption()}
                  endIcon={<ArrowRightIcon />}
                  data-testid="btnAddDro"
                >
                  {t('userAdmin.companyProf.buttonAddDro')}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Permitted Domain Options Card */}
          <Grid item container xs={12}>
            <Grid item container xs={12} alignItems="center" className={classes.tableHeader}>
              <Typography sx={{ fontWeight: 700 }}>{t('userAdmin.companyProf.permittedDomains')}</Typography>
            </Grid>
            <Grid item container xs={12}>
              {listDomains.map((domain: PermittedDomainOption, index: number) => (
                <Grid key={index} item container xs={12} alignItems="center" className={classes.tableRow}>
                  <Grid item container xs={8} justifyContent="flex-start">
                    <Typography sx={{ fontWeight: '300', color: theme.palette.primary.dark }} align="left">
                      {domain.domain}
                    </Typography>
                  </Grid>
                  <Grid item container xs={4} columnSpacing={4} justifyContent="flex-end">
                    <Grid item container md={6} lg={5} xl={4}>
                      {domain.hasUsers ? (
                        <Tooltip key={index} title={t('userAdmin.companyProf.hasUserDomain').toString()}>
                          <Grid container alignItems="center">
                            <Grid
                              item
                              xs={i18n.language.startsWith('en') ? 7 : 10}
                              data-testid={`btnDeleteDomain${index}`}
                              padding={0.9}
                            >
                              <Typography sx={{ fontSize: '11pt', fontWeight: 700 }}>
                                {t('userAdmin.companyProf.delete')}
                                <IconButton size="small" sx={{ padding: '0 1px 0 3px' }}>
                                  <HelpOutlineIcon fontSize="small" sx={{ display: 'inline' }} />
                                </IconButton>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Tooltip>
                      ) : (
                        <Grid container alignItems="center">
                          <Grid item xs={i18n.language.startsWith('en') ? 7 : 10}>
                            <Button
                              fullWidth
                              variant="text"
                              className={classes.buttonText}
                              id={`btnDeleteDomain${index}`}
                              onClick={() =>
                                handleDeleteClick({ itemType: CompanyProfileItemType.Domain, option: domain })
                              }
                              endIcon={<ArrowRightIcon />}
                              data-testid={`btnDeleteDomain${index}`}
                              disabled={!connectedUser.isIbc}
                            >
                              {t('userAdmin.companyProf.delete')}
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item container xs={12} alignItems="center" className={classes.tableRow}>
                <Button
                  variant="text"
                  className={classes.buttonText}
                  id="btnAddDomain"
                  onClick={() => handleAddClickPermittedDomainOption()}
                  endIcon={<ArrowRightIcon />}
                  data-testid="btnAddDomain"
                  disabled={!connectedUser.isIbc}
                >
                  {t('userAdmin.companyProf.buttonAddDomain')}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Notification Recipients Options Card */}
          <Grid item container xs={12}>
            <Grid item container xs={12} alignItems="center" className={classes.tableHeader}>
              <Typography sx={{ fontWeight: 700 }}>{t('userAdmin.companyProf.recipients')}</Typography>
            </Grid>
            <Grid item container xs={12}>
              {listNro.map((recipient: NotificationRecipientOption, index: number) => (
                <Grid key={index} item container xs={12} alignItems="center" className={classes.tableRow}>
                  <Grid item container xs={8} justifyContent="flex-start">
                    <Typography sx={{ fontWeight: '300', color: theme.palette.primary.dark }} align="left">
                      {recipient.email}
                    </Typography>
                  </Grid>
                  <Grid item container xs={4} columnSpacing={4} justifyContent="flex-end">
                    <Grid item container md={6} lg={5} xl={4}>
                      <Grid container alignItems="center">
                        <Grid item xs={i18n.language.startsWith('en') ? 7 : 10}>
                          <Button
                            fullWidth
                            variant="text"
                            className={classes.buttonText}
                            id={`btnDeleteNro${index}`}
                            onClick={() =>
                              handleDeleteClick({ itemType: CompanyProfileItemType.Nro, option: recipient })
                            }
                            endIcon={<ArrowRightIcon />}
                            data-testid={`btnDeleteNro${index}`}
                          >
                            {t('userAdmin.companyProf.delete')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item container xs={12} alignItems="center" className={classes.tableRow}>
                <Button
                  variant="text"
                  className={classes.buttonText}
                  id="btnAddNro"
                  onClick={() => handleAddClickNotificationRecipientOption()}
                  endIcon={<ArrowRightIcon />}
                  data-testid="btnAddNro"
                >
                  {t('userAdmin.companyProf.buttonAddNro')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {addRenameUnitDialogOpen && addRenameUnitDialogProps && <AddRenameUnitDialog {...addRenameUnitDialogProps} />}
      {driverReportAddDialogOpen && driverReportAddDialogProps && (
        <AddEditDriverRptOptionDialog {...driverReportAddDialogProps} />
      )}
      {addRecipientDialogOpen && addRecipientDialogProps && <AddRecipientDialog {...addRecipientDialogProps} />}
      {addDomainDialogOpen && addDomainDialogProps && <AddDomainDialog {...addDomainDialogProps} />}
      {confirmDialogOpen && confirmDialogProps && <ConfirmDialog {...confirmDialogProps} />}
      <Loader open={isLoadingResults} />
    </>
  );
};

export default CompanyProfileResults;
