import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import { DashClients } from '../../../axios';
import Loader from '../../../Components/Loader';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { addNotification } from '../../../Slices/NotificationSlice';
import theme from '../../../theme';
import { Client, ModeEnum, ModeType, Sponsor } from '../../../Types';
import { FormValuesDashClientDetails } from '../DashClientDetails';

interface DeleteSponsorDialogProps {
  mode: ModeType;
  sponsorToDelete: Sponsor;
  formValues: FormValuesDashClientDetails;
  setFormValues: Dispatch<SetStateAction<FormValuesDashClientDetails>>;
  idClient: number;
  clientTypeOfClientEnum: string;
  setClientToDelete: Dispatch<SetStateAction<Client | null>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const DeleteSponsorDialog: FC<DeleteSponsorDialogProps> = ({
  mode,
  sponsorToDelete,
  formValues,
  setFormValues,
  idClient,
  clientTypeOfClientEnum,
  setClientToDelete,
  setOpen,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleError = useErrorHandler();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const enterKeyRef = useRef<HTMLButtonElement>(null);

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      enterKeyRef.current?.click();
    }
  };

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleDelete = async () => {
    setIsLoading(true);
    if (mode === ModeEnum.Update) {
      DashClients.deleteSponsor(sponsorToDelete.id)
        .then(() => {
          dispatch(addNotification(200, 'success', t('dashclients.clientDetails.ok200DeleteSponsor')));

          DashClients.getClientDetails(idClient.toString(), clientTypeOfClientEnum)
            .then((result) => {
              setClientToDelete(result);
              setFormValues({
                ...formValues,
                sponsors: formValues.sponsors.filter((oneSponsor) => oneSponsor.id !== sponsorToDelete.id),
              });
              setOpen(false);
              setIsLoading(false);
            })
            .catch((error) => {
              handleError(error);
              setIsLoading(false);
            });
        })
        .catch((error) => {
          handleError(error);
          setIsLoading(false);
          setOpen(false);
        });
    } else {
      setFormValues({
        ...formValues,
        sponsors: formValues.sponsors.filter((oneSponsor) => oneSponsor.id !== sponsorToDelete.id),
      });
      setIsLoading(false);
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography
        variant="h5"
        sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingTop: theme.spacing(4) }}
        data-testid="deleteDialogSponsor"
      >{`${t('dialog.delete')} ${t('dashclients.clientDetails.deleteSponsorDialogTitle')}`}</Typography>
      <DialogContent sx={{ padding: theme.spacing(4) }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{t('dashclients.clientDetails.deleteSponsorDialogContent')}</Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingBottom: theme.spacing(4) }}
      >
        <Grid container spacing={1} sx={{ justifyContent: 'end' }}>
          <Grid item xs={4}>
            <Button fullWidth data-testid="cancel" variant="contained" color="secondary" onClick={handleClose}>
              {t('dialog.no')}
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              data-testid="delete"
              variant="contained"
              color="primary"
              ref={enterKeyRef}
              onClick={handleDelete}
            >
              {t('dialog.yes')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <Loader open={isLoading} />
    </>
  );
};

export default DeleteSponsorDialog;
