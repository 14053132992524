import React, { FC, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Autocomplete, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';

import { subMonths } from 'date-fns';
import enLocale from 'date-fns/locale/en-CA';
import frLocale from 'date-fns/locale/fr';

import { ReportingCompanies, Units } from '../../axios';
import DashCustomInput from '../../Components/DashCustomInput';
import ExportAsyncCsv from '../../Components/ExportAsyncCsv';
import Message from '../../Components/Message';
import { DATE_FORMAT, DATE_MASK, PAGINATION_PAGESIZE } from '../../Helpers/Constants';
import { formatDateToLocalTime, isValidDate } from '../../Helpers/DateHelper';
import { sortOrganizations, sortRptCompanies, sortUnits } from '../../Helpers/SortHelpers';
import { userFullNameToString } from '../../Helpers/UserHelper';
import useAutofocus from '../../Hooks/UseAutofocus';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import useIbcOrThirdPartySelected from '../../Hooks/UseIbcOrThirdPartySelected';
import { GetAllOrganizations, getOrganizationsByUserAccess } from '../../Slices/OrganizationSlice';
import { getConnectedUser } from '../../Slices/UserSlice';
import theme from '../../theme';
import {
  Functions,
  Organization,
  ReactLocationState,
  ReportHistoryRequest,
  ReportHistoryResponseItem,
  ReportHistoryResponseItemCsv,
  ReportingCompany,
  Unit,
} from '../../Types';
import { PaginationQuery } from '../../Types/PaginationQuery';
import ReportHistoryResults from './ReportHistoryResults';

interface ReportHistorySearchProps {
  results: ReportHistoryResponseItem[] | null;
  countResults: number;
  setResults: React.Dispatch<React.SetStateAction<ReportHistoryResponseItem[] | null>>;
  setCountResults: React.Dispatch<React.SetStateAction<number>>;
  onSearch: (searchParams: ReportHistoryRequest, paginationQuery?: PaginationQuery) => void;
  onExportCSV: (searchParams: ReportHistoryRequest, csvFilename: string) => void;
  fileName: string | undefined;
  resultsCsv: ReportHistoryResponseItemCsv[] | null;
  setResultsCsv: React.Dispatch<React.SetStateAction<ReportHistoryResponseItemCsv[] | null>>;
  csvLink: React.MutableRefObject<any>;
}

const ReportHistorySearch: FC<ReportHistorySearchProps> = ({
  results,
  countResults,
  setResults,
  setCountResults,
  onSearch,
  onExportCSV,
  fileName,
  resultsCsv,
  setResultsCsv,
  csvLink,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const { state } = useLocation<ReactLocationState>();
  const formValuesSaved = state && state.reportHistorySearch ? state.reportHistorySearch : undefined;

  const todayDate = new Date();

  const user = useSelector((state: { user: any }) => getConnectedUser(state));

  const [formValues, setFormValues] = useState<ReportHistoryRequest>(
    formValuesSaved
      ? formValuesSaved
      : {
          driverLicenseNumber: '',
          licenseJurisdiction: '',
          driverName: '',
          referenceNumber: '',
          fromDate: new Date(subMonths(todayDate, 3)),
          toDate: todayDate,
          unitId: null,
          organizationId: null,
          reportingCompanyId: null,
          reportRequestor: userFullNameToString(user),
        }
  );

  const [errorFromDate, setErrorFromDate] = useState<boolean>(false);
  const [errorToDate, setErrorToDate] = useState<boolean>(false);

  const organizations = useSelector((state: { organization: any }) => GetAllOrganizations(state));

  const [isLoadingRpt, setIsLoadingRpt] = useState<boolean>(false);
  const [fetchedRptCompanies, setFetchedRptCompanies] = useState<Array<ReportingCompany> | null>(null);

  const [units, setUnits] = useState<Array<Unit>>([]);
  const [isLoadingUnits, setIsLoadingUnits] = useState<boolean>(false);
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>(
    formValuesSaved && formValuesSaved.unitId ? formValuesSaved.unitId : null
  );

  const isIbcOrThirdPartySelected = useIbcOrThirdPartySelected(formValues.organizationId);

  const [pageNo, setPageNo] = useState<number | null>(null);

  // CSV headers for the CSV export
  const csvHeaders = [
    {
      key: 'organization',
      label: t('reportHistory.table.organization'),
    },
    {
      key: 'company',
      label: t('reportHistory.table.company'),
    },
    {
      key: 'unit',
      label: t('reportHistory.table.unit'),
    },
    {
      key: 'reportRequestor',
      label: t('reportHistory.table.reportRequestor'),
    },
    {
      key: 'reportDate',
      label: t('reportHistory.table.reportDate'),
    },
    {
      key: 'referenceNumber',
      label: t('reportHistory.table.reference'),
    },
    {
      key: 'driverLicenseNumber',
      label: t('reportHistory.table.dln'),
    },
    {
      key: 'driverName',
      label: t('reportHistory.table.driverName'),
    },
  ];

  const organizationRef = useAutofocus();

  useEffect(() => {
    dispatch(getOrganizationsByUserAccess({ functionId: Functions.reportHistory }));
  }, [dispatch]);

  // Initialize selectedOrg if there is only one organizations available to the user
  useEffect(() => {
    if (organizations && organizations.length === 1) {
      setFormValues((prev) => {
        return { ...prev, organizationId: organizations[0].id };
      });
    }
  }, [organizations]);

  // Fetch Reporting Companies
  useEffect(() => {
    if (!isIbcOrThirdPartySelected && formValues.organizationId) {
      setIsLoadingRpt(true);
      ReportingCompanies.getByOrganization(Functions.reportHistory, formValues.organizationId)
        .then((results) => {
          setFetchedRptCompanies(results);
          setIsLoadingRpt(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingRpt(false);
        });
    }
  }, [dispatch, formValues.organizationId, handleError, isIbcOrThirdPartySelected, t]);

  // Fetch Units
  useEffect(() => {
    if (formValues.organizationId && formValues.reportingCompanyId) {
      setIsLoadingUnits(true);
      // Real method
      Units.getUnitsByCompanyId(Functions.reportHistory, formValues.organizationId, formValues.reportingCompanyId)
        .then((results) => {
          setUnits(results);
          setIsLoadingUnits(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingUnits(false);
        });
    }
  }, [formValues.organizationId, formValues.reportingCompanyId, handleError]);

  const handleClearFields = () => {
    setFormValues({
      driverLicenseNumber: '',
      licenseJurisdiction: '',
      driverName: '',
      referenceNumber: '',
      fromDate: new Date(subMonths(todayDate, 3)),
      toDate: todayDate,
      unitId: null,
      organizationId: organizations.length === 1 ? organizations[0].id : null,
      reportingCompanyId: null,
      reportRequestor: userFullNameToString(user),
    });
    setSelectedUnitId(null);
    setResults(null);
    setCountResults(0);
    setResultsCsv(null);

    // clear errors
    setErrorFromDate(false);
    setErrorToDate(false);
  };

  const handleInputChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleUnitAutocompleteChange = (value: number | null) => {
    setSelectedUnitId(value);
    setFormValues({ ...formValues, unitId: value });
  };

  const handleAutocompleteChange = (name: string, value: number | null) => {
    if (name === 'organizationId') {
      setFormValues({
        ...formValues,
        organizationId: value,
        reportingCompanyId: null,
        unitId: null,
      });
      setSelectedUnitId(null);
    } else if (name === 'reportingCompanyId') {
      setFormValues({ ...formValues, reportingCompanyId: value, unitId: null });
      setSelectedUnitId(null);
    }
  };

  const handleDatePickerChange = (fieldName: string, newValue: Date | null) => {
    setFormValues({ ...formValues, [fieldName]: newValue });
  };

  const isFormValid = (): boolean => {
    let error = false;

    if (!isValidDate(formValues.fromDate)) {
      setErrorFromDate(true);
      error = true;
    } else {
      setErrorFromDate(false);
    }

    if (!isValidDate(formValues.toDate)) {
      setErrorToDate(true);
      error = true;
    } else {
      setErrorToDate(false);
    }

    if (error) {
      return false;
    }

    return true;
  };

  const handleSearch = () => {
    if (isFormValid()) {
      const searchParams: ReportHistoryRequest = {
        ...formValues,
        fromDate: formValues.fromDate
          ? new Date(
              formValues.fromDate.getFullYear(),
              formValues.fromDate.getMonth(),
              formValues.fromDate.getDate(),
              0,
              0,
              0,
              0
            )
          : null,
        toDate: formValues.toDate
          ? new Date(
              formValues.toDate.getFullYear(),
              formValues.toDate.getMonth(),
              formValues.toDate.getDate(),
              23,
              59,
              59,
              59
            )
          : null,
        unitId: selectedUnitId,
      };

      const paginationQuery: PaginationQuery = {
        pageSize: PAGINATION_PAGESIZE,
        pageNumber: (pageNo ?? 0) + 1,
      };

      onSearch(searchParams, paginationQuery);
    }
  };

  const fetchCSVData = () => {
    const searchParams: ReportHistoryRequest = {
      ...formValues,
      fromDate: formValues.fromDate
        ? new Date(
            formValues.fromDate.getFullYear(),
            formValues.fromDate.getMonth(),
            formValues.fromDate.getDate(),
            0,
            0,
            0,
            0
          )
        : null,
      toDate: formValues.toDate
        ? new Date(
            formValues.toDate.getFullYear(),
            formValues.toDate.getMonth(),
            formValues.toDate.getDate(),
            23,
            59,
            59,
            59
          )
        : null,
      unitId: selectedUnitId,
    };
    onExportCSV(
      searchParams,
      `${t('menu.reportHistory')} From ${
        formValues.fromDate ? formatDateToLocalTime(formValues.fromDate, false) : ''
      } - To ${formValues.toDate ? formatDateToLocalTime(formValues.toDate, false) : ''}`
    );
  };

  const reportHistoryResultsTable = useRef(null);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPageNo(newPage);
    if (reportHistoryResultsTable.current !== null) {
      window.scrollTo({
        top: reportHistoryResultsTable?.current['offsetTop'] - 108,
        behavior: 'smooth',
      });
    }
  }, []);

  // re-fetch results for new page
  useEffect(() => {
    if (pageNo !== null) handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (pageNo === 0) {
      handleSearch();
    } else {
      setPageNo(0);
    }
  };

  const sort = useCallback(
    (a: any, b: any, type: string): number => {
      switch (type) {
        case 'organization':
          return sortOrganizations(a, b, i18n);
        case 'reportingcompany':
          return sortRptCompanies(a, b, i18n);
        default:
          return 0;
      }
    },
    [i18n]
  );

  return (
    <>
      {true && (
        <Card elevation={3} data-testid="actionsCardHistoryReport">
          <CardContent>
            <form onSubmit={handleFormSubmit}>
              <Grid container data-testid="reportHistorySearchContent">
                <Grid item container spacing={2} padding={2} xs={12}>
                  <Grid item container md={10} lg={10} xl={10} spacing={1}>
                    <Grid item container md={12} lg={11} xl={8}>
                      <Autocomplete
                        fullWidth
                        noOptionsText={t('search.noOption')}
                        value={
                          formValues.organizationId !== null
                            ? organizations.find((oneOrg: Organization) => oneOrg.id === formValues.organizationId)
                            : null
                        }
                        id="organizationId"
                        data-testid="organizationId"
                        options={organizations.sort((a, b) => sort(a, b, 'organization'))}
                        getOptionLabel={(option: Organization) =>
                          i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                        }
                        renderInput={(params) => (
                          <DashCustomInput
                            {...params}
                            label={t('reportHistory.organization')}
                            labelGridSize={3}
                            fieldGridSize={8}
                            variant="outlined"
                            color="secondary"
                            placeholder={t('userAdmin.userAdmin.all')}
                            InputProps={{
                              ...params.InputProps,
                              inputRef: organizationRef,
                            }}
                          />
                        )}
                        onChange={(_, value) =>
                          handleAutocompleteChange('organizationId', value && value.id ? value.id : null)
                        }
                      />
                    </Grid>
                    <Grid item container md={12} lg={11} xl={8}>
                      {!isIbcOrThirdPartySelected ? (
                        <Autocomplete
                          fullWidth
                          loading={isLoadingRpt}
                          loadingText={t('loading')}
                          noOptionsText={t('search.noOption')}
                          value={
                            formValues.reportingCompanyId !== null && fetchedRptCompanies
                              ? fetchedRptCompanies.find((oneRpt) => oneRpt.id === formValues.reportingCompanyId)
                              : null
                          }
                          id="reportingCompanyId"
                          data-testid="reportingCompanyId"
                          options={
                            fetchedRptCompanies
                              ? fetchedRptCompanies.sort((a, b) => sort(a, b, 'reportingcompany'))
                              : []
                          }
                          getOptionLabel={(option: ReportingCompany) =>
                            i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                          }
                          renderInput={(params) => (
                            <DashCustomInput
                              {...params}
                              label={t('reportHistory.companyName')}
                              labelGridSize={3}
                              fieldGridSize={8}
                              variant="outlined"
                              color="secondary"
                              placeholder={t('userAdmin.userAdmin.all')}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {isLoadingRpt ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                        sx={{ marginRight: theme.spacing(4) }}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                          onChange={(_, value) =>
                            handleAutocompleteChange('reportingCompanyId', value && value.id ? value.id : null)
                          }
                          disabled={isIbcOrThirdPartySelected || !formValues.organizationId}
                        />
                      ) : (
                        /* Dummy field for company when IBC or 3rd party */
                        <DashCustomInput
                          fullWidth
                          id="companyHolderIbc3rdPart"
                          value={
                            formValues.organizationId
                              ? i18n.language.startsWith('en')
                                ? organizations.find((oneOrg) => oneOrg.id === formValues.organizationId)?.nameEn
                                : organizations.find((oneOrg) => oneOrg.id === formValues.organizationId)?.nameFr
                              : ''
                          }
                          label={t('reportHistory.companyName')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      )}
                    </Grid>

                    <Grid item container md={12} lg={11} xl={8}>
                      <Autocomplete
                        fullWidth
                        noOptionsText={t('search.noOption')}
                        value={
                          selectedUnitId && units.length > 0
                            ? units.find((oneUnit) => oneUnit.id === selectedUnitId)
                            : null
                        }
                        id="unitId"
                        data-testid="unit"
                        options={units.sort((a: Unit, b: Unit) => sortUnits(a, b))}
                        getOptionLabel={(option: Unit) =>
                          i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                        }
                        renderInput={(params) => (
                          <DashCustomInput
                            {...params}
                            label={t('reportHistory.unit')}
                            labelGridSize={3}
                            fieldGridSize={8}
                            variant="outlined"
                            color="secondary"
                            placeholder={units.length < 1 ? undefined : t('userAdmin.userAdmin.all')}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isLoadingUnits ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                      sx={{ marginRight: theme.spacing(4) }}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                        onChange={(_, value) => handleUnitAutocompleteChange(value ? value.id : null)}
                        loading={isLoadingUnits}
                        loadingText={t('loading')}
                        disabled={!formValues.reportingCompanyId || units.length < 1}
                        // disableClearable={units && units.length === 1}
                      />
                    </Grid>

                    <Grid item container md={12} lg={11} xl={8}>
                      <DashCustomInput
                        fullWidth
                        labelGridSize={3}
                        fieldGridSize={5}
                        variant="outlined"
                        color="secondary"
                        id="reportRequestor"
                        label={t('reportHistory.requestorName')}
                        placeholder={t('userAdmin.userAdmin.all')}
                        value={formValues.reportRequestor}
                        inputProps={{
                          'data-testid': 'reportRequestor',
                          name: 'reportRequestor',
                        }}
                        autoComplete="nope"
                        onChange={handleInputChange}
                      />
                    </Grid>

                    <Grid container item md={12} lg={11} xl={8}>
                      <Grid container alignItems="center" wrap="nowrap">
                        <Grid item xs={3} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
                          <Typography
                            // sx={{
                            //   fontWeight: 700,
                            //   textAlign: 'end',
                            //   marginRight: theme.spacing(2),
                            // }}
                            sx={{ fontWeight: 700, display: 'inline' }}
                          >
                            {t('reportHistory.reportDate')}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} paddingRight={1}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={i18n.language.startsWith('en') ? enLocale : frLocale}
                          >
                            <DatePicker
                              clearable
                              disableFuture
                              mask={DATE_MASK}
                              inputFormat={DATE_FORMAT}
                              value={formValues.fromDate}
                              onChange={(newValue) => handleDatePickerChange('fromDate', newValue)}
                              renderInput={(params) => (
                                <DashCustomInput
                                  {...params}
                                  fullWidth
                                  label={undefined}
                                  fieldGridSize={12}
                                  variant="outlined"
                                  color="secondary"
                                  inputProps={{
                                    ...params.inputProps,
                                    'data-testid': 'fromDatePicker',
                                    placeholder: t('datepickerPlaceholderFrom').toString(),
                                  }}
                                  helperText={(errorFromDate && t('reportHistory.invalidDate')) || (errorToDate && ' ')}
                                  error={errorFromDate}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={3} paddingLeft={1}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={i18n.language.startsWith('en') ? enLocale : frLocale}
                          >
                            <DatePicker
                              clearable
                              disableFuture
                              mask={DATE_MASK}
                              inputFormat={DATE_FORMAT}
                              value={formValues.toDate}
                              onChange={(newValue) => handleDatePickerChange('toDate', newValue)}
                              renderInput={(params) => (
                                <DashCustomInput
                                  {...params}
                                  fullWidth
                                  label={undefined}
                                  inputProps={{
                                    ...params.inputProps,
                                    'data-testid': 'toDatePicker',
                                    placeholder: t('datepickerPlaceholderTo').toString(),
                                  }}
                                  fieldGridSize={12}
                                  variant="outlined"
                                  color="secondary"
                                  helperText={(errorToDate && t('reportHistory.invalidDate')) || (errorFromDate && ' ')}
                                  error={errorToDate}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item container md={12} lg={11} xl={8}>
                      <DashCustomInput
                        fullWidth
                        labelGridSize={3}
                        fieldGridSize={5}
                        variant="outlined"
                        color="secondary"
                        id="referenceNumber"
                        label={t('reportHistory.reference')}
                        value={formValues.referenceNumber}
                        inputProps={{
                          'data-testid': 'referenceNumber',
                          name: 'referenceNumber',
                        }}
                        autoComplete="nope"
                        onChange={handleInputChange}
                      />
                    </Grid>

                    <Grid item container md={12} lg={11} xl={8}>
                      <DashCustomInput
                        fullWidth
                        labelGridSize={3}
                        fieldGridSize={5}
                        variant="outlined"
                        id="driverLicenseNumber"
                        label={t('search.ddlLicenseIdPlaceholder')}
                        value={formValues.driverLicenseNumber}
                        color="secondary"
                        inputProps={{
                          'data-testid': 'driverLicenseNumber',
                          name: 'driverLicenseNumber',
                        }}
                        autoComplete="nope"
                        onChange={handleInputChange}
                      />
                    </Grid>

                    <Grid item container md={12} lg={11} xl={8}>
                      <DashCustomInput
                        fullWidth
                        labelGridSize={3}
                        fieldGridSize={5}
                        variant="outlined"
                        color="secondary"
                        id="referenceNumber"
                        label={t('reportHistory.driverName')}
                        value={formValues.driverName}
                        inputProps={{
                          'data-testid': 'driverName',
                          name: 'driverName',
                        }}
                        autoComplete="nope"
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      wrap="nowrap"
                      md={12}
                      lg={11}
                      xl={8}
                      sx={{ marginTop: theme.spacing(2) }}
                    >
                      <Grid item container xs={3} sx={{ marginRight: theme.spacing(2) }} />
                      <Grid item container spacing={1} xs={6}>
                        <Grid item xs={5}>
                          <Button variant="contained" fullWidth id="btnSearch" type="submit" data-testid="btnSearch">
                            {t('search.btnSearchText')}
                          </Button>
                        </Grid>
                        <Grid item xs={5}>
                          <Button
                            fullWidth
                            onClick={() => handleClearFields()}
                            variant="contained"
                            color="secondary"
                            data-testid="clearFields"
                          >
                            {t('search.clearFields')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item container md={2} lg={2} xl={2} alignContent="flex-end" justifyContent="flex-end">
                    <Grid item md={12} lg={12} xl={9}>
                      <ExportAsyncCsv
                        csvLink={csvLink}
                        hasSearchResult={countResults > 0}
                        csvData={resultsCsv ? resultsCsv : []}
                        csvHeaders={csvHeaders}
                        fileName={fileName}
                        onClick={() => fetchCSVData()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>

            {results && countResults > 0 && (
              <Grid item container padding={2} ref={reportHistoryResultsTable}>
                <Grid item container xs={12}>
                  <ReportHistoryResults
                    searchParams={{
                      ...formValues,
                      unitId: selectedUnitId,
                    }}
                    results={results}
                    count={countResults}
                    pageNo={pageNo ?? 0}
                    handleChangePage={handleChangePage}
                  />
                </Grid>
              </Grid>
            )}
            {results && countResults === 0 && (
              <Grid item container xs={12} padding={2} justifyContent="center" marginTop={theme.spacing(2)}>
                <Message message={t('reportHistory.noResults')} severity="info" />
              </Grid>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
};
export default ReportHistorySearch;
